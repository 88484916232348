import { useEffect, useRef, useState } from "react";
import ImageUploader from "./ImageUploader";
import Button from "../atomic/Button";
import FrontsDropDownArrow from "../../assets/svg/FontDropDownArrow";
import { useOktaAuth } from "@okta/okta-react";

function FontsDropDown({
  setDropdown,
  dropdown,
  selectedFont,
  setFontFamily,
  fontSize,
}: any) {
  const dropDownRef = useRef<any>();

  useEffect(() => {
    const unsubscribe = window.addEventListener("mousedown", (event) => {
      const condition =
        dropDownRef?.current && !dropDownRef?.current?.contains(event.target);
      if (condition) return setDropdown(false);
    });

    return () => unsubscribe;
  }, []);

  return (
    <div className="relative inline-block text-left z-[2000]" ref={dropDownRef}>
      <div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setDropdown((prevState: boolean) => !prevState);
          }}
          type="button"
          className="inline-flex items-center w-full px-3 py-2 text-sm font-semibold text-primary uppercase"
          id="menu-button"
        >
          Change Style
          <FrontsDropDownArrow
            className={`mx-3 ${dropdown ? "" : "rotate-180"}`}
          />
        </button>
      </div>

      {dropdown && (
        <div
          className="absolute right-0 top-8 z-10  w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
        >
          <div className="py-1" role="none">
            {["Dancing Script", "Birthstone", "Ephesis", "Great Vibes"].map(
              (font) => (
                <button
                  key={font}
                  onClick={() => {
                    setFontFamily(font);
                    setDropdown(false);
                  }} // Set selected font
                  className={`block px-4 py-2 w-full text-left text-gray-700 hover:bg-[#EDEFF1] ${fontSize} ${selectedFont === font ? "bg-[#EDEFF1]" : ""
                    }`}
                  style={{ fontFamily: font, fontWeight: 700 }} // Preview font style in dropdown
                >
                  {font}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function CustomTextSignature({
  fontFamily,
  setFontFamily,
  text,
  setText,
}: {
  readonly fontFamily: string;
  readonly setFontFamily: (val: string) => void;
  readonly text: string;
  readonly setText: (val: string) => void;
}) {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const fontSize = "text-xl";

  return (
    <div className="bg-transparent flex flex-col items-end text-black  absolute z-[999] w-[97%] text-md ">
      <input
        className={`border-b-2 bg-transparent w-full px-4 pb-2 outline-none ${fontSize}`}
        style={{ fontWeight: 400, fontFamily: fontFamily }}
        placeholder="Type Here"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <FontsDropDown
        dropdown={dropDownOpen}
        setDropdown={setDropDownOpen}
        selectedFont={fontFamily}
        setFontFamily={setFontFamily}
        fontSize={fontSize}
      />
    </div>
  );
}

function CustomSignatureInput({
  handleSubmission,
  submitting,
  hasIDG,
  children
}: {
  handleSubmission: (signature: string) => void;
  submitting: boolean;
  hasIDG?: boolean;
  children?: any
}) {
  const canvasRef = useRef<any>(null);
  const contextRef = useRef<any>(null);
  const [drawing, setDrawing] = useState<boolean>(false);
  const [drawingPresent, setDrawingPreset] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("Type");
  const [fontFamily, setFontFamily] = useState<string>("Dancing Script");
  const [text, setText] = useState<string>("");
  const [image, setImage] = useState<null | File>(null);
  const [generatedTextSignature, setGeneratedTextSignature] =
    useState<boolean>(false);
  const canvasWidth = 600;
  const { authState } = useOktaAuth();
  const textMissing = mode === "Type" && text === "";
  const imageMissing = mode === "Upload" && image === null;
  const drawingMissing = mode === "Draw" && drawingPresent === false;

  useEffect(() => {
    if (mode !== "Upload") {
      const canvas = canvasRef.current;
      const context = canvas?.getContext?.("2d");

      context.strokeStyle = "black";
      context.lineWidth = 2;
      contextRef.current = context;
    }

    setDrawingPreset(false);
    setText("");
  }, [mode]);

  const startDrawing = ({ nativeEvent }: any) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    setDrawing(true);
    nativeEvent.preventDefault();
  };

  const draw = ({ nativeEvent }: any) => {
    if (!drawing) return;
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    nativeEvent.preventDefault();
  };

  const stopDrawing = () => {
    if (!drawing) return;
    contextRef.current.closePath();
    setDrawing(false);
    setDrawingPreset(true);
  };

  const Tab = ({
    title,
    onClick,
    isActive,
  }: {
    title: string;
    onClick: any;
    isActive: boolean;
  }) => {
    return (
      <button
        className={`pr-5 pb-4 ${isActive ? "border-primary border-b-4 " : "pb-5"
          }`}
        onClick={onClick}
      >
        <p className="font-semibold text-sm !text-left">{title}</p>
      </button>
    );
  };
  const clear = () => {
    canvasRef?.current?.getContext?.("2d")?.reset?.();
    setGeneratedTextSignature(false);
    setDrawingPreset(false);
    setText("");
  };
  const Tabs = () => (
    <div className="w-full flex justify-left border-b border-roundedInputBorder">
      {[
        {
          title: "Type",
          onClick: () => {
            clear();
            setMode("Type");
          },
        },
        {
          title: "Draw",
          onClick: () => {
            clear();
            setMode("Draw");
          },
        },
        // {
        //   title: "Upload",
        //   onClick: () => {
        //     clear();
        //     setMode("Upload");
        //   },
        // },
      ].map((tab) => (
        <Tab {...tab} isActive={mode === tab.title} />
      ))}
    </div>
  );

  async function handleSubmit() {
    if (mode === "Type" || mode === "Draw") {
      contextRef.current.font = `20px ${fontFamily}`;
      contextRef.current.fillText(text, canvasWidth / 2.6, 58);

      if (mode === "Type") setGeneratedTextSignature(true);
      handleSubmission(canvasRef.current.toDataURL("image/png"));
    }

    /**
     * Upload Mode currently disabled
     */
    // if (mode === "Upload" && image) {
    //   mutate({
    //     username: "",
    //     password: "",
    //     currentDate: moment().format("MM-DD-YYYY"),
    //     orderIds,
    //     image,
    //   });
    // }
  }

  return (
    <div
      className={`rounded-md flex justify-between flex-col min-w-[${canvasWidth}px]`}
    >
      {children}
      <Tabs />
      <div className="my-5 relative justify-center flex items-center">
        {mode !== "Upload" && (
          <canvas
            draggable={true}
            onMouseDown={startDrawing}
            onMouseUp={stopDrawing}
            onMouseMove={mode === "Draw" ? draw : undefined}
            onMouseLeave={stopDrawing}
            ref={canvasRef}
            className="border cursor-pointer !bg-sideNavGray rounded-md "
            height={mode === "Draw" ? 200 : 116}
            width={canvasWidth}
          />
        )}
        {mode === "Upload" && (
          <ImageUploader
            image=""
            labelInfo={{
              inputId: "signature",
              required: false,
              label: "",
            }}
            name="signature"
            value={image}
            id="signature"
            disabled={false}
            onChange={(event: any) => {
              setImage(event.target.value ?? null);
            }}
          />
        )}
        {mode === "Type" && generatedTextSignature === false && (
          <CustomTextSignature
            text={text}
            fontFamily={fontFamily}
            setFontFamily={setFontFamily}
            setText={setText}
          />
        )}
      </div>
      <div className="flex items-center mb-5">
        <p className="font-semibold text-md text-requiredRed mr-1 text-sm">
          Note:
        </p>
        <p className="text-sm font-normal">
          This signature will be used for future authorizations
        </p>
      </div>
      <div className="self-end flex">
        <Button
          btnType="secondary"
          onClick={clear}
          title={"Clear"}
          disabled={textMissing || drawingMissing || submitting}
          className={`mx-2`}
        />
        <Button
          btnType="primary"
          disabled={textMissing || drawingMissing || submitting}
          title={hasIDG ? "Next" : "Submit"}
          submitting={submitting}
          onClick={handleSubmit}
          className={`font-semibold`}
        />
      </div>
    </div>
  );
}

export default CustomSignatureInput;
