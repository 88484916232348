import axios, { AxiosResponse } from "axios";
import envVars from "../env-configs";

export const instance = axios.create({
  baseURL: envVars.REACT_APP_SERVER_URL,
});
const selectedEndpoints = [
  "/v1/auth/new-flow/register",
  "/v1/orders/new-flow/create",
  "/v1/orders/new-flow",
  "v1/organizations/new-flow/create",
  "v1/sof/create-sof",
  "v1/sof/update-sof",
  "v1/users/invite",
  "/v1/additional-clinicians",
  "v1/orders/new-flow/approval",
];
instance.interceptors.request.use(async (config: any) => {
  try {
    if (selectedEndpoints.some((link) => config.url.includes(link))) {
      // Skipping the url where sourceipaddress doesn't needs to be sent
      if (
        config.url.includes("/v1/orders/new-flow/add-document") ||
        config.url.includes("/v1/orders/new-flow/multiple-download") ||
        config.url.includes("v1/orders/new-flow/pdf-orders")
      ) {
        return config; // Return config without adding the IP address
      }

      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();

      // Check if the URL is exactly '/v1/orders/new-flow/approval' to send sourceIpAddress instead of SourceIpAddress
      if (config.url === "v1/orders/new-flow/approval") {
        // Attach 'sourceIpAddress' for this specific endpoint
        config.data = {
          ...config.data,
          sourceIpAddress: data.ip, // lowercase version
        };
      } else {
        // Attach 'SourceIpAddress' for other endpoints
        config.data = {
          ...config.data,
          SourceIpAddress: data.ip, // default version
        };
      }
    }
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
  return config;
});

const responseBody = (response: AxiosResponse) => {
  let data = response.data;
  data.status = response.status;

  return data;
};

const requests = {
  get: async (url: string, config?: {}) =>
    await instance.get(url, config).then(responseBody),
  post: async (url: string, body: {}, config?: {}) =>
    await instance.post(url, body, config).then(responseBody),
  put: async (url: string, body: {}, config?: {}) =>
    await instance.put(url, body, config).then(responseBody),
  patch: async (url: string, body?: {}, config?: {}) =>
    await instance.patch(url, body, config).then(responseBody),
  delete: async (url: string, config?: {}) =>
    await instance.delete(url, config).then(responseBody),
};

export default requests;
