function getExt(filename: string): any {
 const ext = filename?.split(".")?.pop();
 if (ext === filename) return "";
 return ext;
}

function orderResponseFormatter(data: any) {
 let history_physical_exam: any = {};
 let pathology_surgical_findings: any = {};
 let isAltheaDxNeuropsychotropicMedications: any = {};
 let isIDgNeuropsychotropicMedicationsCurrent: any = {};
 let isIDgNeuropsychotropicMedications: any = {};
 let isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement: any = {};
 const patientFirstName = !!data?.patientInfo?.firstName
  ? data?.patientInfo?.firstName
  : !!data?.patientName
  ? data?.patientName?.split?.(" ")[0]
  : !!data.patientFirstName
  ? data.patientFirstName
  : "";
 const patientLastName = !!data?.patientInfo?.lastName
  ? data?.patientInfo?.lastName
  : !!data?.patientName
  ? data?.patientName?.split?.(" ")[1]
  : !!data.patientLastName
  ? data.patientLastName
  : "";

 let questionsValues = {
  question1: !!data?.isSentinelLymphNodeBiopsy
   ? data?.isSentinelLymphNodeBiopsy === "Unknown"
     ? "N/A"
     : data.isSentinelLymphNodeBiopsy
   : data.testTypeId === "4" || data.testTypeId === "7"
   ? data.isExposedToRadiation === null
     ? "N/A"
     : data.isExposedToRadiation
   : !!data?.isPrimaryCutaneousMelanocyticNeoplasm
   ? data?.isPrimaryCutaneousMelanocyticNeoplasm
   : "",

  question2: !!data?.isClinicallyPalpableNodes
   ? data?.isClinicallyPalpableNodes
   : !!data?.isSpitzoidFeatures
   ? data?.isSpitzoidFeatures
   : !!data?.isReportPrame
   ? data.isReportPrame === true
     ? "Yes"
     : "No"
   : "",
  question3: !!data?.isIntrasitMetastasis
   ? data?.isIntrasitMetastasis
   : data?.isLesionJunctional
   ? data?.isLesionJunctional
   : "",
  // question4:
  //  data?.isCastleStandardTesting === true
  //   ? "CST"
  //   : data?.isDDMNotReported === true
  //   ? "MPO"
  //   : "DMO",
 };
 let skippedDocs = null;
 let transitDocument: any = [];
 let transitDocumentsFiles: any = [];
 let eSignatureFiles: any = [];
 let additionalClinicians: any = {};
 let checkedIndexes: any = [];
 let has_additional_clinician: null | boolean;
 let supportingDocsData: any = {
  pathology_report: null,
  patient_info_demographics: null,
  // signed_letter: null,
  insurance_cards: null,
 };

 if (data?.isAdditionalClinician === true) {
  has_additional_clinician = true;
  // attestation = true;
 } else if (data?.isAdditionalClinician === false) {
  has_additional_clinician = false;
  // attestation = false;
 } else {
  has_additional_clinician = null;
 }

 skippedDocs =
  data.isSupportingDocuments === true
   ? false
   : data.isSupportingDocuments === false
   ? true
   : data.isSupportingDocuments;

 if (data?.supportingDocuments) {
  // let keys: any = {
  //  //  pathology_report: "patholog-report",
  //  //  signed_letter: "signed-letter",
  //  //  patient_info_demographics: "demographics-sheet",
  // //  insurance_cards: "insurance-card",
  // };
  let keys: any = {
   "patholog-report": "pathology_report",
   "demographics-sheet": "patient_info_demographics",
   "signed-letter": "signed_letter",
   "insurance-card": "insurance_cards",
   "current-prescription-medications": "current_prescription_medications",
  };

  data?.supportingDocuments.map((item: any) => {
   supportingDocsData[keys[item.type]] = `${item.container}/${item.filename}`;
   skippedDocs = false;
   supportingDocsData[keys[item.type] + "File"] = {
    name: `${item.container}/${item.filename}`,
    type: getExt(item.filename),
   };
   supportingDocsData[keys[item.type] + "Url"] = item.url;
  });
 }
 eSignatureFiles = data?.supportingDocuments
  ?.filter((e: any) => e?.type === "e-signature")
  ?.map((item: any) => ({
   name: item.filename,
   type: getExt(item.filename),
   existing: true,
   url: item?.url,
  }));

 if (!!data?.secondaryTestTypeId) {
  checkedIndexes = [data?.testTypes?.id, Number(data.secondaryTestTypeId)];
 } else {
  checkedIndexes = [data?.testTypes?.id];
 }

 transitDocument =
  data?.transitDocument && data?.transitDocument?.length > 0
   ? data?.transitDocument.map((item: any) => ({
      ...item,
      filePath: `${item.container}/${item.filename}`,
     }))
   : [];
 transitDocumentsFiles =
  data?.transitDocument && data?.transitDocument?.length > 0
   ? data?.transitDocument.map((item: any) => {
      return {
       name: item.filename,
       type: getExt(item.filename),
       existing: true,
      };
     })
   : [];

 history_physical_exam = {
  options: data?.historyPhysicalExam ?? [],
 };
 pathology_surgical_findings = {
  options: data?.pathologySurgicalFindings
   ? data.pathologySurgicalFindings.map((item: any) => {
      if (item === "Invasion beyond subcutaneous fat")
       return "Depth: Invasion beyond subcutaneous fat";
      return item;
     })
   : [],
 };
 isAltheaDxNeuropsychotropicMedications = {
  options: data?.isAltheaDxNeuropsychotropicMedications ?? [],
 };
 isIDgNeuropsychotropicMedicationsCurrent = {
  options: data?.isIDgNeuropsychotropicMedicationsCurrent ?? [],
 };
 isIDgNeuropsychotropicMedications = {
  options: data?.isIDgNeuropsychotropicMedications ?? [],
 };
 isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement = {
  options: data?.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement ?? [],
 };

 return {
  orderId: data?.id,
  eRequestStatus: data?.eRequestStatus,
  selectedTest: data?.testTypes
   ? {
      id:
       data?.testTypes?.id == 6
        ? 3
        : data?.testTypes?.id == 7
        ? 4
        : data?.testTypes?.id,
      name: data?.testTypes?.name,
      checkedIndexes: checkedIndexes,
     }
   : null,
  orderType: data?.orderType,
  orderStatus: data?.orderStatus ?? "",
  status: data?.status ?? 0,
  eSignatureID: data?.eSignatureId,
  eSignatureFiles,
  // patient info
  patientId: data?.patientId ?? "",
  patientName: data?.patientName ?? "",
  submittedDate: data?.submittedDate ?? "",
  patient_firstname: patientFirstName,
  patient_lastname: patientLastName,
  // patient_lastname: !!data?.patientInfo?.lastName ?? "",
  patient_middleinitial: data?.patientInfo?.middleInitial ?? "",
  additional_notes: data?.notes ?? "",
  previous_notes: data?.notes ?? "",
  patient_dob: data?.patientDOB ?? "",
  patient_gender: data?.patientInfo?.gender ?? "",
  patient_ssn: data?.patientInfo?.ssn ?? "",
  patient_mrn: data?.patientInfo?.mrn ?? "",
  patient_email: data?.patientInfo?.email ?? "",
  patient_address: data?.patientInfo?.address ?? "",
  patient_city: data?.patientInfo?.city ?? "",
  patient_state: data?.patientInfo?.state ?? "",
  patient_phone: data?.patientInfo?.phone ?? "",
  patient_zip_code: data?.patientInfo?.zipcode ?? "",
  // billing info and medicare
  icd_10: !!data?.billingInfo?.icdCodeId ? data?.billingInfo?.icdCodeId : "",
  additionalCodes: !!data?.billingInfo?.additionalCodes
   ? data?.billingInfo?.additionalCodes
   : [],
  icdCodeId: !!data?.billingInfo?.icdCodeId ? data?.billingInfo?.icdCodeId : "",
  insurance: data?.insuranceCompanyName ?? "",
  primaryInsuranceId: !!data?.billingInfo?.primaryInsuranceId
   ? data?.billingInfo?.primaryInsuranceId
   : "",
  icn: data?.billingInfo?.insuranceCompanyName ?? "",
  billing_phone: data?.billingInfo?.phone ?? "",
  policy: data?.billingInfo?.policyNumber ?? "",
  medicare: data?.typeOfFacility ?? "",
  date_of_discharge: data?.billingInfo?.dateOfDischarge ?? "",
  // additional info
  has_additional_clinician: has_additional_clinician,
  // has_additional_clinician:
  //  data?.additionalPhysician && data?.additionalPhysician?.length > 0
  //   ? true
  //   : false,
  // additionalInfo: data?.additionalPhysician ?? [],
  // ...additionalClinicians,
  additionalClinician: data?.additionalClinician ?? [],
  attestation: data?.isAttestation ?? false,
  // lab info
  typeofspecimen: data?.typeOfSpecimen ?? "",
  labortaryId: !!data?.labortaryId ? data?.labortaryId : "",
  // name_of_facility: !!data?.pathologyLab?.DataRecordName
  //  ? data?.pathologyLab?.DataRecordName
  //  : "",
  // name_of_facility: !!data?.pathologyLab?.name ? data?.pathologyLab?.name : "",
  name_of_facility: !!data?.labortaryName
   ? data?.labortaryName
   : !!data?.pathologyLab?.name
   ? data?.pathologyLab?.name
   : "",
  date_of_collection: data?.labortaryInfo?.dateOfCollection ?? "",
  facility_contact_person: data?.labortaryInfo?.facilityContactPerson ?? "",
  lab_phone: data?.labortayInfoPhone ?? "",
  lab_fax: data?.labortayInfoFax ?? "",
  specimenId: data?.specimenId ?? "",
  // organization info
  organization_id: data?.organization?.id ?? "",
  organization_limsRecordId: data?.organization.limsRecordId ?? "",
  organization_name: data?.organization?.name ?? "",
  organization_emai: data?.organization?.email ?? "",
  organization_phone: data?.organization?.phone ?? "",
  organization_fax: data?.organization?.fax ?? "",
  organization_description: data?.organization?.description ?? "",
  organization_address: data?.organization?.address ?? "",
  organization_city: data?.organization?.city ?? "",
  organization_state: data?.organization?.state ?? "",
  zipcode: data?.organization?.zipcode ?? "",
  // clinician info
  clinician_id: data?.physician?.id ?? "",
  clinician_limsRecordId: data?.physician?.physicianLimsRecordId ?? "",
  clinician_firstName: data?.physician?.firstName ?? "",
  clinician_lastName: data?.physician?.lastName ?? "",
  clinician_ssn: data?.physician?.ssn ?? " ",
  clinician_mrn: data?.physician?.mrn ?? "",
  clinician_full_name: data?.physician?.firstName ?? "",
  clinician_email: data?.physician?.email ?? "",
  clinician_phone: data?.physician?.primaryPhone ?? "",
  clinician_suffix: data?.physician?.suffix ?? "",
  clinician_speciality: data?.physician?.specialty ?? "",
  clinician_npi: data?.physician?.npi ?? "",
  clinician_account_number: data?.physician?.limsRecordId ?? "",
  clinician_address: data?.physician?.streetAddress ?? "",
  clinician_city: data?.physician?.city ?? "",
  clinician_zip_code: data?.physician?.zipCode ?? "",
  clinician_state: data?.physician?.state ?? "",
  orderComments: data?.orderComments,
  orderExceptions: data?.orderExceptions,
  // clinical info section
  history_physical_exam: !!data?.otherHistoryPhysicalExam?.[0]
   ? {
      ...history_physical_exam,
      options: [...history_physical_exam.options, "Other"],
     }
   : history_physical_exam,
  pathology_surgical_findings,
  isAltheaDxNeuropsychotropicMedications,
  isIDgNeuropsychotropicMedicationsCurrent,
  // isIDgNeuropsychotropicMedications,
  isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement,
  lymphNodeStatus: data?.lymphNodeStatus
   ? data.lymphNodeStatus === "Nia"
     ? "N1a"
     : data.lymphNodeStatus
   : "",
  other:
   data?.otherHistoryPhysicalExam?.length > 0 && data?.testTypes?.id == 2
    ? data?.otherHistoryPhysicalExam[0]
    : data?.otherIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement
    ? data.otherIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement[0]
    : "",
  ...questionsValues,
  // supporting Docs
  noSOF: data?.noSOF ?? false,
  isExpiredSOF: data?.isExpiredSOF ?? false,
  sofMismatch: data?.sofMismatch ?? false,
  skippedDocs: skippedDocs,
  ...supportingDocsData,
  transitDocuments: transitDocument ?? [],
  transitDocumentsFiles: transitDocumentsFiles ?? [],
  //Clinical Info
  isSentinelLymphNodeBiopsy: data?.isSentinelLymphNodeBiopsy ?? "",
  isClinicallyPalpableNodes: data?.isClinicallyPalpableNodes ?? "",
  isExposedToRadiation:
   data?.isExposedToRadiation === null ? "N/A" : data.isExposedToRadiation,
  isReportPrame: !!data?.isReportPrame
   ? data?.isReportPrame === true
     ? "Yes"
     : data?.isReportPrame === false
     ? "No"
     : "No"
   : "",
  isIntrasitMetastasis: data?.isIntrasitMetastasis ?? "",
  // isMelanocyticLesionUncertain: data?.isMelanocyticLesionUncertain ?? "",
  isPrimaryCutaneousMelanocyticNeoplasm:
   data?.isPrimaryCutaneousMelanocyticNeoplasm ?? "",
  // isSpitzoidFeatures: data?.isSpitzoidFeatures ?? "",
  // isLesionJunctional: data?.isLesionJunctional ?? "",
  createdAt: data?.createdAt ?? "",
  test_type: data?.testTypes?.name ?? "",
  orderReport: data?.orderReport ?? null,
  // isCastleStandardTesting: data?.isCastleStandardTesting ?? true,
  // isDDMNotReported: data?.isDDMNotReported ?? false,
  // isMyPathNotReported: data?.isMyPathNotReported ?? false,
  eRequestId: data?.eRequestId ?? null,
  paperTRFDocuments: data?.paperTRFDocuments ? data?.paperTRFDocuments : [],
  isPhysicianSignPermission: data?.isPhysicianSignPermission
   ? data.isPhysicianSignPermission
   : false,
  icdCodeValue: data?.icdCodeDetails?.value ?? "",
  facilityType: data?.facilityType ? data.facilityType : "",
  icdCodeName: data?.icdCodeDetails?.name ?? "",
  isTeleHealth: data?.isTeleHealth ?? false,
  tubeBarcode: data?.tubeBarcode ?? "",
  pathologist: data?.pathologist ?? "",
  // workingDiagnosis: data?.workingDiagnosis ?? "",
  tumorSite: data?.tumorSite ?? "",
  canReOrder: data?.canReOrder ?? "",
  icd10CodeMismatch: data?.icd10CodeMismatch,
  medicationMismatch: data?.medicationMismatch,
  medicarePullDate: data?.medicarePullDate,
  fedexLabelId: data?.fedexLabelId,
  orderDate: data?.orderDate,
 };
}

export default orderResponseFormatter;
