import { Form, Formik } from "formik";
import CustomInput from "../global/CustomInput";
import OrganizationSignupValues from "../../models/forms/organizationsignupformvalues.interface";
import OrganizationSignupValidation from "../../validations/organizationsignupvalidation";
import Button from "../atomic/Button";
import { useDispatch, useSelector, connect } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import CustomImageUploader from "../global/CustomImageUploader";
import { formRef } from "../../Pages/Admin/ManageScreen/index";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";
import { missingTests } from "../../utils/helpers/missingTestsValidator";
import { ActionArgs } from "../../Store/Actions/types";
import OrganizationActions from "../../Store/Actions/OrganizationsActions";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../utils/helpers/toastMessage";
import { useHistory } from "react-router-dom";
import SignUpFormActions from "../../Store/Actions/SignUpFormActions";
import { cityStateHelper } from "../../utils/helpers/cityStateHelper";
import ProfileActions from "../../Store/Actions/ProfileActions";
import dataConstants from "../../constants/dataConstants";
import { useLocation } from "react-router-dom";
import RequiredFieldTooltip from "../global/RequiredFieldTooltip";
import TestsList from "./TestOrderForms/TestsList";
import ActiveTestsActions from "../../Store/Actions/ActiveTestsActions";
import ToggleSwitch from "../atomic/ToggleSwitch";

interface OrganizationSignUpFormProps {
  handleNext: (data: any) => any;
  setImage?: (image: any) => any;
  createOrganization: (data: ActionArgs) => void;
  updateOrganization: (data: ActionArgs) => void;
  getOrganizations: (data: any) => void;
  fetchProfile?: (data: any) => void;
  isPage: boolean;
  orgId?: string;
  organizationValues?: OrganizationSignupValues;
  setFieldValue?: any;
  isCompletion?: any;
  fetchActiveTests: (args: any) => void;
}

function RenderFields({
  isCompletion,
  isPage,
  initialValues,
  setFieldValue,
  reset,
  errors,
  touched,
  setImage,
  isValid,
  values,
  orgId,
  validateForm,
  ...rest
}: any) {
  const dispatch = useDispatch();
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );

  useEffect(() => {
    if (values && values.state) {
      const foundState = states.find(
        (state: any) => `${state.name} ${state.state_code}` == values.state
      );

      if (foundState) {
        setCities(foundState.cities);
      }
    }
  }, []);

  const [cities, setCities] = useState([]);
  const profile = useSelector((state: any) => state.Profile.profile);
  const newOrganization =
    useLocation()?.pathname === "/manage/organizations/new";
  function handleChange(name: string, type: "event" | "val", data: any) {
    const updatedValues = { ...values };

    function handleValue(val: any) {
      updatedValues[name] = val;

      if (val == "") {
        dispatch(ButtonStateActions.toggleSumbit(false));
      } else {
        validateForm(updatedValues)
          .then((res: any) => {
            if (Object.keys(res).length === 0) {
              dispatch(ButtonStateActions.toggleSumbit(!missingTests(values)));
            } else {
              dispatch(ButtonStateActions.toggleSumbit(false));
            }
          })
          .catch((err: any) => {
            dispatch(
              ButtonStateActions.toggleSumbit(!missingTests(values) && isValid)
            );
          });
      }
    }

    if (type == "event") {
      setFieldValue(name, data.target.value);
      handleValue(data.target.value);
    } else {
      if (name == "state") {
        cityStateHelper(states, setCities, data, values, setFieldValue, "city");
        setFieldValue(name, data);
        setFieldValue(
          "stateValue",
          states.find((state: any) => state.state_code === data)?.name ?? data
        );
        handleValue(data);
      } else {
        setFieldValue(name, data);
        handleValue(data);
      }
    }
  }

  function onUploadStart() {
    dispatch(ButtonStateActions.toggleSumbit(false));
  }
  function onUploadEnd() {
    dispatch(ButtonStateActions.toggleSumbit(!missingTests(values) && isValid));
  }
  return (
    <div className={`${isPage ? "w-[100%]" : "w-[100%] pt-[24px]"}`}>
      {!isPage && !newOrganization && (
        <div className="bg-lightBlue -mt-2 p-2 px-4 rounded my-4 font-semibold flex text-base text-royalBlue">
          <p
            style={{ height: 15, width: 15 }}
            className={
              "mt-1 mr-2 cursor-pointer rounded-full bg-primary text-center text-white font-medium text-xs"
            }
          >
            i
          </p>
          <p>
            Only test types are editable, contact{" "}
            <a
              href="mailto:clinicalservices@castlebiosciences.com"
              className="hover:underline hover:text-base hover:font-semibold text-blue-600"
            >
              CS team
            </a>{" "}
            for any other changes
          </p>
        </div>
      )}
      {!isPage && (
        <CustomImageUploader
          fieldName={"logo"}
          upperTitle={"Logo"}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          initialImage={orgId ? values.logoUrl : ""}
          setFieldValue={setFieldValue}
          handleImage={setImage}
          title={"CHANGE LOGO"}
          disabled={profile?.roles?.includes("Physician")}
          hideChange={!orgId ? true : false}
          className={"lg:w-7/12"}
          reset={reset}
        />
      )}
      <CustomInput
        id={"organization_name"}
        key={"organization_name"}
        name={"organization_name"}
        disabled={orgId}
        label={"Organization Name"}
        isInternal={!isPage}
        onChange={(val: any) => handleChange("organization_name", "event", val)}
        required
        placeholder={"Enter organization name"}
        value={values.organization_name}
        errors={errors}
        touched={touched}
        className={`mt-0 ${isPage ? "pt-0" : ""}`}
        signupFieldsStyle={"-mt-1"}
      />
      <CustomInput
        id={"description"}
        key={"description"}
        name={"description"}
        label={"Nickname"}
        // isTextArea={true}
        disabled={!isPage && !newOrganization}
        // isInternal={!isPage}
        onChange={(val: any) =>
          setFieldValue("description", val.currentTarget.textContent)
        }
        required={false}
        placeholder={"Enter nickname"}
        value={values.description}
        errors={errors}
        touched={touched}
        signupFieldsStyle={"-mt-1"}
      />
      {/* <CustomInput
      id={'email_id'}
      key={'email_id'}
      name={'email_id'}
      label={'Organization Email ID'}
      disabled={!isPage && !newOrganization}
      required
      placeholder={'Enter email ID'}
      isInternal={!isPage}
      onChange={(val: any) => handleChange('email_id', 'event', val)}
      value={values.email_id}
      errors={errors}
      touched={touched}
      className={'mt-4'}
      signupFieldsStyle={'-mt-1'}
    /> */}
      <div className={!isPage ? "flex justify-between items-center" : ""}>
        <CustomInput
          id={"phone"}
          key={"phone"}
          name={"phone"}
          label={"Phone"}
          required
          disabled={!isPage && !newOrganization}
          placeholder={"000-000-0000"}
          type={"tel"}
          value={values.phone}
          // onChange={(val: any) => setFieldValue('phone', val, true)}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("phone", "event", val)}
          errors={errors}
          touched={touched}
          className={!isPage ? "mr-2" : ""}
          signupFieldsStyle={"-mt-1"}
        />
        <CustomInput
          id={"fax"}
          key={"fax"}
          name={"fax"}
          label={"Fax"}
          required={true}
          disabled={!isPage && !newOrganization}
          placeholder={"000-000-0000"}
          type={"tel"}
          value={values.fax}
          errors={errors}
          // onChange={(val: any) => setFieldValue('fax', val, true)}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("fax", "event", val)}
          touched={touched}
          className={!isPage ? "ml-2" : ""}
          signupFieldsStyle={"-mt-1"}
        />
      </div>
      <CustomInput
        id={"address"}
        key={"address"}
        name={"address"}
        label={"Address"}
        disabled={!isPage && !newOrganization}
        signupFieldsStyle={"-mt-1"}
        required
        placeholder={"Enter street address"}
        value={values.address}
        errors={errors}
        isInternal={!isPage}
        onChange={(val: any) => handleChange("address", "event", val)}
        touched={touched}
      />
      <CustomInput
        id={"address2"}
        key={"address2"}
        name={"address2"}
        label={"Address 2"}
        disabled={!isPage && !newOrganization}
        signupFieldsStyle={"-mt-1"}
        required={false}
        placeholder={"Enter street address"}
        value={values.address2}
        errors={errors}
        isInternal={!isPage}
        onChange={(val: any) => handleChange("address2", "event", val)}
        touched={touched}
      />
      <CustomInput
        id={"state"}
        key={"state"}
        name={"state"}
        label={"State/Province"}
        disabled={!isPage && !newOrganization}
        required
        placeholder={"Enter state"}
        // suggestions={!error && states.length > 0 ? states.map((state: any) => { return `${state.name} ${state.state_code}` }) : []}
        suggestions={
          !error && states.length > 0
            ? states.map((state: any) => ({
                label: state.name,
                value: state.state_code,
              }))
            : []
        }
        isInternal={!isPage}
        onChange={
          states && states.length > 0
            ? (val: any) => handleChange("state", "val", val)
            : (val: any) => handleChange("state", "event", val)
        }
        value={
          values?.stateValue
            ? values.stateValue
            : values.state
            ? states &&
              states.length > 0 &&
              states.find((state: any) =>
                `${state?.name} ${state?.state_code}`.includes(values?.state)
              )?.name
            : values.state
        }
        errors={errors}
        touched={touched}
        signupFieldsStyle={"-mt-1"}
      />
      <div className={!isPage ? "flex justify-between items-center" : ""}>
        <CustomInput
          id={"city"}
          key={"city"}
          name={"city"}
          label={"City"}
          disabled={!isPage && !newOrganization}
          required
          placeholder={"Enter city name"}
          // suggestions={cities && cities.length > 0 ? cities.map((city: any) => { return city.name }) : []}
          suggestions={
            cities && cities.length > 0
              ? cities.map((city: any) => ({
                  label: city.name,
                  value: city.name,
                }))
              : []
          }
          value={values.city}
          errors={errors}
          isInternal={!isPage}
          onChange={
            cities && cities.length > 0
              ? (val: any) => handleChange("city", "val", val)
              : (val: any) => handleChange("city", "event", val)
          }
          touched={touched}
          className={!isPage ? "mr-2" : ""}
          signupFieldsStyle={"-mt-1"}
        />
        <CustomInput
          id={"zip_code"}
          key={"zip_code"}
          name={"zip_code"}
          label={"Zip Code"}
          disabled={!isPage && !newOrganization}
          required
          placeholder={"Enter zip code"}
          value={values.zip_code}
          errors={errors}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("zip_code", "event", val)}
          className={!isPage ? "ml-2" : ""}
          touched={touched}
          signupFieldsStyle={"-mt-1"}
        />
      </div>
    </div>
  );
}

function OrganizationSignUpForm({
  handleNext,
  setFieldValue,
  isPage,
  organizationValues,
  createOrganization,
  updateOrganization,
  getOrganizations,
  fetchProfile,
  orgId,
  fetchActiveTests,
}: OrganizationSignUpFormProps) {
  const [image, setImage] = useState<any>();
  const { oktaAuth, authState } = useOktaAuth();
  const { data } = useSelector((state: any) => state.Organizations);
  const [testsMissing, setTestMissing] = useState<boolean>(false);
  const formValues = useSelector((state: any) => state.SignUpFormValues);
  const [reset, setReset] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [listHeight, setListHeight] = useState(
    window.innerHeight - window.innerHeight * 0.22
  );
  const setDimension = () => {
    if (window.innerHeight <= 370) {
      setListHeight(window.innerHeight - window.innerHeight * 0.46);
    } else if (window.innerHeight <= 400) {
      setListHeight(window.innerHeight - window.innerHeight * 0.4);
    } else if (window.innerHeight <= 450) {
      setListHeight(window.innerHeight - window.innerHeight * 0.35);
    } else if (window.innerHeight <= 480) {
      setListHeight(window.innerHeight - window.innerHeight * 0.36);
    } else if (window.innerHeight <= 500) {
      setListHeight(window.innerHeight - window.innerHeight * 0.34);
    } else if (window.innerHeight <= 530) {
      setListHeight(window.innerHeight - window.innerHeight * 0.31);
    } else if (window.innerHeight <= 570) {
      setListHeight(window.innerHeight - window.innerHeight * 0.29);
    } else if (window.innerHeight <= 600) {
      setListHeight(window.innerHeight - window.innerHeight * 0.26);
    } else if (window.innerHeight <= 630) {
      setListHeight(window.innerHeight - window.innerHeight * 0.26);
    } else if (window.innerHeight <= 655) {
      setListHeight(window.innerHeight - window.innerHeight * 0.24);
    } else if (window.innerHeight <= 680) {
      setListHeight(window.innerHeight - window.innerHeight * 0.23);
    } else if (window.innerHeight <= 700) {
      setListHeight(window.innerHeight - window.innerHeight * 0.23);
    } else if (window.innerHeight <= 715) {
      setListHeight(window.innerHeight - window.innerHeight * 0.23);
    } else if (window.innerHeight <= 720) {
      setListHeight(window.innerHeight - window.innerHeight * 0.23);
    } else if (window.innerHeight <= 750) {
      setListHeight(window.innerHeight - window.innerHeight * 0.26);
    } else if (window.innerHeight <= 800) {
      setListHeight(window.innerHeight - window.innerHeight * 0.2);
    } else if (window.innerHeight <= 850) {
      setListHeight(window.innerHeight - window.innerHeight * 0.22);
    } else if (window.innerHeight <= 880) {
      setListHeight(window.innerHeight - window.innerHeight * 0.18);
    } else if (window.innerHeight <= 900) {
      setListHeight(window.innerHeight - window.innerHeight * 0.21);
    } else if (window.innerHeight <= 980) {
      setListHeight(window.innerHeight - window.innerHeight * 0.16);
    } else if (window.innerHeight <= 1050) {
      setListHeight(window.innerHeight - window.innerHeight * 0.15);
    } else {
      setListHeight(window.innerHeight - window.innerHeight * 0.2);
    }
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    // calling to initially set list height on basis of screen height
    setDimension();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    fetchActiveTests({
      onError: (message: string) => {
        toastMessages.error(message);
      },
    });
    if (orgId) {
      dispatch(ButtonStateActions.toggleSumbit(true));
    }
  }, []);

  let initialValues: OrganizationSignupValues;

  if (orgId) {
    const organization = data.find((org: any) => org.id == orgId);
    const { cbsTests } = organization;

    initialValues = {
      logo: organization?.logo ?? "",
      logoUrl: organization?.logoUrl ?? "",
      organization_name: organization?.name ?? "",
      description: organization?.description ?? "",
      // email_id: organization?.email ?? '',
      phone: organization?.phone ?? "",
      fax: organization?.fax ?? "",
      address: organization?.address ?? "",
      address2: organization?.address2 ?? "",
      city: organization?.city ?? "",
      zip_code: organization?.zipcode ?? "",
      state: organization?.state ?? "",
      scc: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("SCC")),
      cm: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("CM")),
      um: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("UM")),
      tc: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("TC")),
      ddm: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("DDM")),
      idg: !!(cbsTests && cbsTests.length > 0 && cbsTests.includes("IDG")),
      isEmailEnabled: organization?.isEmailEnabled ?? false,
    };
  } else {
    initialValues = {
      organization_name: formValues.organization_name ?? "",
      description: formValues.description ?? "",
      // email_id: formValues.email_id ?? '',
      phone: formValues.phone ?? "",
      fax: formValues.fax ?? "",
      address: formValues.address ?? "",
      address2: formValues.address2 ?? "",
      city: formValues.city ?? "",
      zip_code: formValues.zip_code ?? "",
      state: formValues.state ?? "",
      scc: formValues.scc ?? false,
      cm: formValues.cm ?? false,
      um: formValues.um ?? false,
      tc: formValues.tc ?? false,
      ddm: formValues.ddm ?? false,
      idg: formValues.idg ?? false,
      isEmailEnabled: formValues.isEmailEnabled ?? false,
    };
  }

  function handleImage(image: any) {
    setImage(image);
  }
  function resetLogo() {
    setFieldValue("logo", "");
  }

  function onSubmit(data: OrganizationSignupValues) {
    if (
      !data.cm &&
      !data.scc &&
      !data.tc &&
      !data.um &&
      !data.ddm &&
      !data.idg
    ) {
      return setTestMissing(true);
    }
    setTestMissing(false);
    if (isPage) {
      handleNext(data);
    } else {
      if (orgId) {
        const apiData = {
          ...data,
          orgId,
          accessToken: authState?.accessToken?.accessToken,
        };

        const onSuccess = (message: string) => {
          const options = {
            onError: (message: string) =>
              toastMessages.error(
                message ??
                  "Failed to fetch organizations, Please refresh the app"
              ),
            config: {
              headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
              },
            },
          };
          getOrganizations(options);
          dispatch(ButtonStateActions.toggleSumbit(false));
          toastMessages.success(message ?? "Organization Updated", 3000);
          dispatch(ButtonStateActions.setButtonState("organization detail"));
        };

        const onError = (message: string) => {
          toastMessages.error(message ?? "Organization Update Failed", 3000);
        };

        updateOrganization({ data: apiData, onSuccess, onError });
      } else {
        createOrganization({
          data: { ...data, accessToken: authState?.accessToken?.accessToken },
          onSuccess: (message: string) => {
            const options = {
              onError: (message: string) =>
                toastMessages.error(
                  message ??
                    "Failed to fetch organizations, Please refresh the app"
                ),
              config: {
                headers: {
                  Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                },
              },
            };
            getOrganizations(options);
            const profileOptions = {
              accessToken: authState?.accessToken?.accessToken,
              onSuccess: () => {
                // console.log('success')
              },
              onError: (message: string) => {
                toastMessages.error(message);
                // alert('Something went wrong')
              },
            };
            if (fetchProfile) fetchProfile(profileOptions);
            formRef?.current?.resetForm();
            resetLogo();
            history.push("/manage/organizations");
          },
          onError: (message: any) => {
            toastMessages.error(message, 3000);
          },
        });
      }
    }
  }

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    ...rest
  }: any) {
    return (
      <Form
        // className={isPage ? 'justify-betweeen flex flex-col justify-around h-5/6' : 'flex flex-col overflow-scroll h-full'}
        className={
          isPage
            ? "h-screen flex flex-col justify-around overflow-auto w-full"
            : "-mt-6 flex flex-col px-12 overflow-auto"
        }
        style={{ height: listHeight }}
      >
        <div
          // className={isPage ? 'overflow-auto px-1 h-4/5' : 'overflow-scroll px-12 h-3/6 pb-20 md:pb-5 lg:h-4/6 xl:pb-0 2xl:h-5/6'}
          className={isPage ? "overflow-auto px-1 mb-4" : ""}
        >
          <RenderFields
            isPage={isPage}
            isValid={isValid}
            values={values}
            reset={reset}
            orgId={orgId}
            initialValues={initialValues}
            setFieldValue={setFieldValue}
            setImage={handleImage}
            errors={errors}
            touched={touched}
            {...rest}
          />
          <RequiredFieldTooltip
            required={true}
            showLeft={true}
            tooltipText={"Required Field"}
          >
            <p className="text-royalBlue text-sm after:content-['*'] after:ml-1 after:text-requiredRed mt-5">
              Choose Castle Biosciences Test Services (all that apply)
            </p>
          </RequiredFieldTooltip>
          <TestsList
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            isPage={isPage}
            isValid={isValid}
            values={values}
          />
          {testsMissing && (
            <p className="text-xs text-requiredRed mt-2">
              Atleast one test must be selected
            </p>
          )}
        </div>
        {!isPage && (
          <div className="w-full  block pb-5">
            <p className="text-sm mb-2">Allow Email Alerts</p>
            <ToggleSwitch
              name={"isEmailEnabled"}
              disabled={false}
              id="switch"
              checked={values && values?.isEmailEnabled}
              handleChange={(e) => {
                setFieldValue("isEmailEnabled", e.target.checked);
              }}
            />
            {/* <CustomInput
              id={"isEmailEnabled"}
              key={"isEmailEnabled"}
              name={"isEmailEnabled"}
              label={values && values?.isEmailEnabled ? "Yes" : "No"}
              checked={values && values?.isEmailEnabled}
              showToolTip
              tooltipText={"Send COA Email"}
              required={false}
              placeholder={""}
              errors={null}
              touched={null}
              type="checkbox"
              className="my-1"
              onChange={(e: any) => {
                setFieldValue("isEmailEnabled", e.target.checked);
              }}
            /> */}
          </div>
        )}
        {/* </div> */}

        {isPage && (
          <div className="mb-2 w-full">
            <Button
              type={"submit"}
              btnType="primary"
              title={"next"}
              className={"w-full shadow"}
              onClick={() => {}}
            />
            <div className="flex items-center justify-center mt-4">
              <div className={"h-3 w-3 rounded-full bg-primary mr-1"} />
              <div className={"h-3 w-3 rounded-full bg-primaryDisabled mr-1"} />
              <div className={"h-3 w-3 rounded-full bg-primaryDisabled "} />
            </div>
          </div>
        )}
      </Form>
    );
  }

  function handleReset() {
    setReset((prevState: boolean) => !prevState);
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={isPage && OrganizationSignupValidation}
      onSubmit={onSubmit}
      onReset={handleReset}
      initialStatus={false}
      isInitialValid={!!orgId}
      render={Render}
    />
  );
}

const mapDispatchToProps = {
  createOrganization: OrganizationActions.createOrganization,
  updateOrganization: OrganizationActions.updateOrganization,
  getOrganizations: OrganizationActions.fetchOrganizations,
  fetchProfile: ProfileActions.fetchprofile,
  fetchActiveTests: ActiveTestsActions.fetchActiveTests,
};
export default connect(null, mapDispatchToProps)(OrganizationSignUpForm);
