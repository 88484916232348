import { useEffect, useState } from 'react'

function CustomImage({ src, alt, className }: { src: string, alt: string, className?: string }) {
  const [error, setError] = useState<boolean>(false)

  function handleError() {
    setError(true)
  }
  function onLoad() {
    setError(false)
  }

  useEffect(() => { }, [src])

  if (error) { return <img src={require('../../assets/images/NoImage.png')} className={className ?? 'object-contain w-[7rem] h-[7rem] rounded-full'} /> }
  if (src === undefined) { return <img src={require('../../assets/images/SignatureImage.png')} /> }

  return <img
    onError={handleError}
    onLoad={onLoad}
    src={src}
    alt={alt}
    className={className ?? 'object-fit w-[7rem] h-[7rem] rounded-full'}
  />
}

export default CustomImage
