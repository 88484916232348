/* eslint-disable @typescript-eslint/space-before-function-paren */
import { AnimatePresence, motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";
import LogoSVG from "../../assets/svg/Logo";
import SideMenuIcon from "../../assets/svg/SideMenuIcon";
import { formRef } from "../../Pages/Admin/ManageScreen/index";
import Button from "../atomic/Button";
import RenderButtons from "../molecular/RenderHeaderButtons";
import Dropdown from "./Dropdown";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector, connect } from "react-redux";
import HeaderRightLoadingIndicator from "./LoadingIndicators/HeaderRightLoadingIndicator";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import ArrowUp from "../../assets/svg/ArrowUp";
import React, { useEffect, useRef, useState } from "react";
import {
  resetSelectedPhysician,
  updateSelectedOrganization,
  updateSelectedPhysician,
} from "../../Store/Actions/OnlineOrderFormActions";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";
import { LOGOUT, TOGGLE_SESSION_STATE } from "../../Store/Actions/types";
import OrganizationActions from "../../Store/Actions/OrganizationsActions";
import toastMessages from "../../utils/helpers/toastMessage";
import PhysiciansDropDown from "./PhysiciansDropDown";
import PathLabDropdown from "./PathLabDropdown";
import './styles.css'
import NotificationActions from "../../Store/Actions/NotificationsActions";
import { purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import socket from "../../server";

function RenderUser({ profile }: any) {
  const dropDownRef = useRef<any>();
  const { oktaAuth, authState } = useOktaAuth();
  const [visibility, setVisiblity] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  function openProfile() {
    history.push("/profile", { replace: true });
    setVisiblity(false);
  }

  function handleVisibility() {
    setVisiblity((state: boolean) => !state);
  }

  const logout = async () => {
    try {
      const response = await oktaAuth.signOut({clearTokensBeforeRedirect:true});
      setVisiblity(false);
      dispatch({ type: LOGOUT, payload: true });
      dispatch({ type: TOGGLE_SESSION_STATE, payload: true })
      // setTimeout(() => {
      //   socket.disconnect()
      // }, 1000)
    } catch (error) {
      // console.log(error)
    }
  };

  useEffect(() => {
    const unsubscribe = window.addEventListener("mousedown", (event) => {
      if (
        dropDownRef?.current &&
        !dropDownRef?.current?.contains(event.target)
      ) {
        return setVisiblity(false);
      }
    });

    return () => unsubscribe;
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      <div ref={dropDownRef} className="relative mx-3 z-50">
        <button
          onClick={handleVisibility}
          className="flex justify-between items-center"
        >
          <p
            className={`p-1 px-3 hover:text-[#18B5B7] text-sm ${visibility ? "text-primary" : "text-royalBlue"
              }`}
          >
            {profile.firstName}
          </p>
          <button
            onClick={handleVisibility}
            type="button"
          // className={`${visibility ? "rotate-180 " : ""}`}
          // className={`${visibility ? "rotate-180 " : ""}`}
          >
            {visibility ? (
              <ArrowUp height={8} width={25} onClick={handleVisibility} />
            ) : (
              <DropDownIcon height={8} width={25} onClick={handleVisibility} />
            )}
            {/* <DropDownIcon
              height={8}
              width={25}
              onClick={handleVisibility}
              className={`${visibility ? "" : ""}`}
            /> */}
          </button>
        </button>
        {visibility && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ minWidth: "100%" }}
            className="absolute top-8 w-full shadow-md p-2 border rounded-md bg-white"
          >
            <ul>
              <li>
                <button
                  onClick={openProfile}
                  type="button"
                  className="text-center my-2 w-full"
                >
                  <p className={"text-sm hover:text-[#18B5B7] text-royalBlue"}>
                    Profile
                  </p>
                </button>
              </li>
              <li>
                <button
                  onClick={logout}
                  type="button"
                  className="text-center my-2 w-full"
                >
                  <p className={"text-sm hover:text-[#18B5B7] text-royalBlue"}>
                    Logout
                  </p>
                </button>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  );
}

function HeaderRightSection({
  fetchOrganizationsWithoutUsers,
  fetchingOrgUsers,
  fetchingOrgUsersError,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const { authState }: any = useOktaAuth();
  const initiallyGetRole = authState?.accessToken?.claims?.Profile?.roles
  const [physicians, setPhysicians] = useState<any>();
  const [organizations, setOrganizations] = useState<any>();
  const { fetching: loadingOrganizations, error: organizationsError } =
    useSelector((state: any) => state.Organizations);
  const { fetching, profile } = useSelector((state: any) => state.Profile);
  const { organizationData } = useSelector((state: any) => {
    return {
      organizationData: state.Organizations.data,
    };
  });
  const { selectedOrganization } = useSelector(
    (state: any) => state.OnlineOrderForm
  );

  function OrganizationSelect(val: any): void {
    const selectedOrg = organizationData.filter(
      (e: any) => e?.id === val?.id
    )[0];
    let organizationProfileRoleCheck = false;
    if (selectedOrg) {
      organizationProfileRoleCheck =
        profile?.organizationRoles[selectedOrg?.id]?.roles?.length == 1 &&
        profile?.organizationRoles[selectedOrg?.id]?.roles?.includes(
          "Physician"
        );
    }

    dispatch(updateSelectedOrganization(selectedOrg));

    if (selectedOrg?.id !== selectedOrganization?.id) {
      const shouldResetPhysician =
        (profile?.roles?.length > 1 && !selectedOrg) ||
        (!profile?.roles?.includes("Physician"));

      if (shouldResetPhysician) {
        dispatch(resetSelectedPhysician());
      }
    }

    if (organizationProfileRoleCheck) {
      dispatch(updateSelectedPhysician(profile));
    } else if (selectedOrg === undefined) {
      dispatch(resetSelectedPhysician());
    }
  }
  function handleOrgMore() {
    if (fetchingOrgUsers) return;

    const options = {
      onError: (message: string) =>
        toastMessages.error(
          message ?? "Failed to fetch organizations, Please refresh the app"
        ),
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      },
    };
    fetchOrganizationsWithoutUsers(options);
  }
  useEffect(() => {
    let isPLUPhysician = profile?.roles?.includes("Physician") && profile?.roles?.includes("PathLab User")
    let foundOrganizations = organizationData?.map((e: any) => {
      return {
        id: e.id,
        label: e?.name,
        image: e?.logoUrl,
        city: e.city,
        state: e.state,
        address: e.address,
      };
    });
    if (foundOrganizations) {
      setOrganizations([
        { id: 0, label: "All Organizations" },
        ...foundOrganizations,
      ]);
    }
    if (profile?.roles?.includes("Physician") && profile?.roles?.length == 1) {
      dispatch(updateSelectedPhysician(profile));
      dispatch(OrganizationActions.updateFetchingUsersToFalse());
    } else if (isPLUPhysician) {
      dispatch(updateSelectedPhysician(profile));
    }
  }, [organizationData, profile, selectedOrganization]);
  if (initiallyGetRole && initiallyGetRole?.roles?.includes('Staff')) {
    return <React.Fragment />;
  } else if (fetching || loadingOrganizations) {
    return <HeaderRightLoadingIndicator />;
  }


  return (
    <div className="flex justify-between items-center">
      {(profile?.roles?.includes("Collaborative Order Agreement") || profile?.roles?.includes("PathLab User")) && (
        <PathLabDropdown />
      )}
      {(profile?.roles?.includes("PathLab User") && profile?.roles?.length == 1) ? null : (
        <Dropdown
          handleChange={(val: any) => OrganizationSelect(val)}
          selectedValue={selectedOrganization?.name}
          selectedImage={selectedOrganization?.logoUrl}
          isOrg={true}
          data={organizations}
          text={"All Organizations"}
          style={{
            minWidth: organizations == null ? "110%" : "309px",
            maxHeight: "200px",
          }}
          loadMoreOrgs={() => handleOrgMore()}
        // disable
        />
      )}
      {(profile?.roles?.length === 1 &&
        profile?.roles?.includes("Physician")) ||
        profile?.roles?.includes("PathLab User") ? null : (
        <PhysiciansDropDown />
      )}
      <RenderUser profile={profile} />
    </div>
  );
}

function Header({
  isAuthenticated,
  isInternal,
  title,
  screen,
  showBack,
  onBackScreen,
  showTitleLoading,
  fetchingOrgUsers,
  fetchingOrgUsersError,
  fetchOrganizationsWithoutUsers,
  CustomButtons,
  checkHeaderTitle,
  setMenuOpen,
  isSettingsChanged
}: any): JSX.Element {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  // () => navigate('/login')
  const { profile } = useSelector((state: any) => state?.Profile)
  const handleLogin = async (): Promise<any> => {
    purgeStoredState({ storage: storage, key: "root" });
    await oktaAuth.signInWithRedirect({ originalUri: "/login-process" });
  };
  const history = useHistory();
  if (isInternal) {
    return (
      <AnimatePresence exitBeforeEnter>
        <header
          className={
            "flex justify-between border-b font-medium items-center px-6 py-3 min-h-[59px] max-h-[100px] overflow-hidden sticky top-0 bg-white z-10"
          }
        >
          <div className="flex items-center ">
            {showBack && (
              <motion.button
                // key={title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  if (onBackScreen || isSettingsChanged === false) {
                    history.goBack();
                    dispatch(ButtonStateActions.setButtonState(onBackScreen))
                  } else if (isSettingsChanged) {
                    dispatch(NotificationActions.showNotification({
                      type: 'success',
                      title: 'Notification Settings',
                      hideClose: true,
                      message: `The changes you've made may not be saved if you leave this page.`,
                      customFunction() {
                      },
                      buttons: [
                        {
                          onClick: () => {
                            dispatch(NotificationActions.hideNotifications())
                          },
                          title: 'STAY',
                          type: 'secondary',
                        },
                        {
                          onClick: () => {
                            history.push('/notifications')
                            dispatch(NotificationActions.hideNotifications())
                          },
                          title: 'LEAVE',
                          type: "primary",
                        }
                      ]
                    }))
                  }
                }}
                type="button"
                className={"rotate-90 -ml-3 mt-2.5 mr-3"}
              >
                <DropDownIcon height={20} width={25} />
              </motion.button>
            )}
            {showTitleLoading ? (
              <div className="py-1 rounded-md px-8 bg-dropDownBorder animate-pulse" />
            ) : (
              <motion.p
                key={title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={`capitalize text-royalBlue max-w-xl break-normal ${checkHeaderTitle &&
                  "overflow-y-auto max-h-[100px] lg:w-[80%] 2xl:w-full xl:w-full xl:text-base 2xl:text-base lg:text-sm  2xl:ml-0 xl:ml-0 lg:-ml-4]"
                  }`}
              >
                {title === "create organization" ? "add organization" : title}
              </motion.p>
            )}
          </div>
          {CustomButtons ? (
            <CustomButtons />
          ) : (
            <RenderButtons
              click1={() => {
                formRef?.current?.submitForm();
                // console.log(formRef.current.submitForm())
              }}
              mode={""}
              fetchDashboard={() => { }}
              screen={""}
              toggleSubmit={() => { }}
              toggleMode={() => { }}
              setButtonState={() => { }}
            />
          )}
        </header>
      </AnimatePresence>
    );
  }

  return (
    <header
      className={`flex justify-between border items-center px-6 ${profile?.roles?.includes('Collaborative Order Agreement') ? 'tab:py-11 py-3' : 'py-3'} ${isAuthenticated ? "h-16" : ""
        }`}
    >
      <div className="flex items-center">
        {isAuthenticated && (
          <button className="pr-6 toggleButton" onClick={() => setMenuOpen((prevState: boolean) => !prevState)}>
            <SideMenuIcon height={20} width={20} />
          </button>
        )}
        <Link to={isAuthenticated ? "/dashboard" : "/"}>
          <LogoSVG height={34} width={100} />
        </Link>
      </div>
      {history.location.pathname !== "/" && history.location.pathname !== '/verify-email' && (
        <div>
          {isAuthenticated ? (
            <HeaderRightSection
              fetchingOrgUsers={fetchingOrgUsers}
              fetchingOrgUsersError={fetchingOrgUsersError}
              fetchOrganizationsWithoutUsers={fetchOrganizationsWithoutUsers}
            />
          ) : (
            <Button btnType="primary" onClick={handleLogin} title={"Login"} />
          )}
        </div>
      )}
    </header>
  );
}

const mapStateToProps = (state: any) => ({
  fetchingOrgUsers: state.Organizations.fetchingOrgUsers,
  fetchingOrgUsersError: state.Organizations.fetchingOrgUsersError,
});

const mapDispatchToProps = {
  fetchOrganizationsWithoutUsers:
    OrganizationActions.fetchOrganizationsWithoutUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
