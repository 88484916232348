import { useRef, ChangeEvent } from "react";
import CustomImage from "./CustomImage";

export default function ImageUploader({
  value,
  onChange,
  name,
  id,
  disabled
}: any) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileUrl = URL.createObjectURL(file);
      onChange({
        target: {
          name: name ? name : "logo",
          value: file,
        },
      });
    }
  };

  return (
    <button type="button" onClick={() => inputRef.current?.click()} disabled={disabled} className={`${disabled && 'cursor-not-allowed'} mt-5`}>
      <CustomImage src={value instanceof File ? URL.createObjectURL(value) : !!value ? value: ""} alt="Upload Image" />
      <p className="font-semibold mt-3">Upload Image</p>
      <input
        ref={inputRef}
        name={!!name ? name : "logo"}
        id={!!id ? id : "logo"}
        type="file"
        onChange={handleInputChange}
        hidden
      />
    </button>
  );
}
