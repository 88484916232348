import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { Fragment, useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ProfileScreenLayout from "../../../Containers/ProfileLayout";
import ButtonStateActions from "../../../Store/Actions/ButtonStateActions";
import ProfileActions from "../../../Store/Actions/ProfileActions";
import { useOktaAuth } from "@okta/okta-react";
import Button from "../../../components/atomic/Button";
import { useHistory } from "react-router-dom";
import CustomImage from "../../../components/global/CustomImage";
import UserDetailLoadingIndicator from "../../../components/global/LoadingIndicators/UserDetailLoadingIndicator";
import { ITableHeaderProps } from "../../../components/DataTableHeader/DataTableHeader";
import DataTable, {
  DataTableState,
} from "../../../components/DataTable/DataTable";
import OrganizationPillBox from "../../../components/global/OrganizationPillBox";
import OrganizationWithRoleBox from "../../../components/global/OrganizationWithRoleBox";
import AssociatedOrganisationsAndRolesTable from "../../../components/global/Tables/AssociatedOrganisationsAndRolesTable";
import SOFFlow from "../../../components/organism/SofFlow";
import DropdownSOF from "../../../components/DataTable/DropdownSOF";
import RequiredFieldTooltip from "../../../components/global/RequiredFieldTooltip";
import physicianToClinician from "../../../utils/helpers/physicianToClinician";
import UserSOFTable from "../../../components/global/Tables/UserSOFTable";
import AssociatedPathLabAndRolesTable from "../../../components/global/Tables/AssociatedPathLabAndRolesTable";
import toastMessages from "../../../utils/helpers/toastMessage";
import http from "../../../Store/API/HttpMethods";
import CustomPopup from "../../../components/global/CustomPopup";
import CustomSignatureInput from "../../../components/global/CustomSignatureInput";
import FileActions from "../../../utils/helpers/FileActions";

function ProfileHomeScreen({
  screen,
  headerTitle,
  setButtonState,
  fetchProfile,
  fetching,
  error,
  profile,
  tableData,
  sofTable,
  orderAgreementTable,
  fetchOrderAgreementOfUser,
}: any) {
  const { authState } = useOktaAuth();
  const history = useHistory();
  const [signatureId, setSignatureId] = useState<string | null>(null);
  const [showSignaturePopup, setShowSignaturePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const { data: states, error: statesError } = useSelector(
    (state: any) => state.StateAndCity
  );
  const dispatch = useDispatch();
  const [creating, setCreating] = useState<boolean>(false);
  const [signatureData, setSignatureData] = useState<any>();
  const [deletingSignature, setDeletingSignature] = useState<boolean>(false)
  const [sofState, setSofState] = useState<DataTableState>({
    sofType: "create",
    sofVisible: false,
    item: null,
  });

  async function checkSignature(): Promise<void> {

    try {
      const options = {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      };

      const response = await http.checkSignature(
        `v1/esignature/my-esignature`,
        options
      );

      const { statusCode, data, error } = response;
      setSignatureId(response.id);
      setSignatureData(response)
      if (statusCode === 200 && data) {
        return data;
      } else {
        throw new Error(error);
      }
    } catch (error: any) {
      if (error.response) {
        toastMessages.error(error.response.data.message);
      }
    }
  }

  function requestPayload(data: any): Promise<{
    blobUrl?: string;
  }> {
    return new Promise(async (resolve, reject) => {
      if (data.file) {
        try {
          const fileActions = new FileActions();
          let file: any = await fileActions.convertBase64ToImage(
            data.file?.base64url ?? "",
            data.file?.fileName ?? ""
          );
          const filePath = await fileActions.uploadToAzure({
            type: "images/esignatures",
            file,
            token: authState?.accessToken?.accessToken ?? "",
          });

          return resolve({
            blobUrl: filePath,
          });
        } catch (error: any) {
          return reject(new Error(error.message));
        }
      }

      return resolve({
        blobUrl: "",
      });
    });
  }

  async function createSignature(data: { file?: any }, accessToken: string): Promise<void> {
    setCreating(true);

    try {
      const { blobUrl } = await requestPayload(data);

      const payload = {
        blobUrl: blobUrl ?? "",
      };

      const response = await http.createSignature("/v1/esignature", payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { statusCode, message } = response;
      if (response.status === 201 || message === "Success") {
        toastMessages.success(
          response?.data?.message ?? "E-signature created successfully"
        );
        setShowSignaturePopup(false)
        checkSignature()
      }
    } catch (error: any) {
      toastMessages.error(error?.message || "An error occurred");
    } finally {
      setCreating(false);
    }
  }

  async function updateSignature(data: { file?: any }, accessToken: string): Promise<void> {

    setCreating(true);
    try {
      const { blobUrl } = await requestPayload(data);

      const payload = {
        blobUrl: blobUrl ?? "",
      };

      const response = await http.updateSignature("v1/esignature/updateSignature", payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { statusCode, message } = response;
      if (response.status === 200 || message === "Success") {
        toastMessages.success(
          response?.data?.message ?? "E-signature updated successfully"
        );
        setShowSignaturePopup(false)
        checkSignature()
      }
    } catch (error: any) {
      toastMessages.error(error?.message || "An error occurred");
    } finally {
      setCreating(false);
    }
  }

  async function handleDeleteSignature(signatureId: number): Promise<void> {
    setDeletingSignature(true);
    try {

      const response = await http.deleteSignature(`v1/esignature/my-esignature`, undefined, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      });

      const { message } = response;
      if (response.status === 200 || message === "Success") {
        toastMessages.success(
          response?.data?.message ?? "E-signature deleted successfully"
        );
        setDeletingSignature(false)
        setSignatureData(null)
        setSignatureId(null);
        setShowDeletePopup(false);
        checkSignature()
      }
    } catch (error: any) {
      toastMessages.error(error?.message || "An error occurred");
    } finally {
      setDeletingSignature(false);
    }
  }

  async function handleSubmission(signatureUrl: string) {
    const accessToken = authState?.accessToken?.accessToken;

    if (!accessToken) {
      toastMessages.error("Access token not available");
      return;
    }

    if (!!signatureId) {
      await updateSignature({
        file: {
          fileName: `${profile?.id}_${profile?.firstName}_signature_file.png`,
          base64url: !!signatureUrl ? signatureUrl : !!signatureId ? signatureId : '',
        }
      }, accessToken);
    } else {
      await createSignature({
        file: {
          fileName: `${profile?.id}_${profile?.firstName}_signature_file.png`,
          base64url: !!signatureUrl ? signatureUrl : !!signatureId ? signatureId : '',
        }
      }, accessToken);
    }
  }


  function hidePopup(): void {
    setShowSignaturePopup(false);
  }

  function SOFClick({
    type,
    item,
  }: {
    type: "create" | "update" | "view" | "delete" | "update-req-pending";
    item: any;
  }) {
    setSofState({ sofVisible: true, sofType: type, item });
  }
  function changePassword() {
    history.push("/forget-password", { replace: true });
  }

  let keys: any;

  if (
    profile &&
    Array.isArray(profile.roles) &&
    profile.roles.includes("Physician")
  ) {
    keys = {
      // firstName: 'First Name',
      // lastName: 'Last Name',
      firstName: "Full Name",
      email: "Email ID",
      roles: "Role",
      // primaryPhone: 'Phone',
      // fax: 'Fax',
      // city: 'City',
      // zipCode: 'ZIP Code',
      // state: 'State/Province',
      // streetAddress: 'Address',
      suffix: "Suffix",
      npi: "NPI",
      specialty: "Speciality",
      signPermission: "Sign Permission",
    };
  } else {
    keys = {
      // firstName: 'First Name',
      // lastName: 'Last Name',
      firstName: "Full Name",
      email: "Email ID",
      roles: "Role",
      // primaryPhone: 'Phone',
      // fax: 'Fax',
      // city: 'City',
      // zipCode: 'ZIP Code',
      // state: 'State/Province',
      // streetAddress: 'Address'
    };
  }

  useEffect(() => {
    setButtonState("my profile");
    let options = {
      accessToken: authState?.accessToken?.accessToken,
      onSuccess: () => {
        console.log("success");
      },
      onError: (error: any) => {
        console.log(error);
      },
    };
    fetchProfile(options);
  }, []);

  useEffect(() => {
    if (
      profile?.roles?.includes("Collaborative Order Agreement") &&
      !fetching
    ) {
      fetchOrderAgreementOfUser(
        profile?.id,
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
        (message: string) => {
          // history.replace('/manage/portal-users')
          toastMessages.error(message);
        }
      );
    }
  }, [profile?.user?.roles, fetching]);

  useEffect(() => {
    if (profile?.roles?.includes("Physician")) {
      checkSignature();
    }
  }, []);

  if (fetching && !error) {
    return <UserDetailLoadingIndicator showTable={false} />;
  }

  if (!fetching && error) {
    return (
      <div>
        <p>Something went wrong...</p>
      </div>
    );
  }

  return (
    <ProfileScreenLayout headerTitle={headerTitle} screen={screen}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={"col-span-5"}
        >
          <p className="text-royalBlue p-5 font-semibold !font-xs">
            User Detail
          </p>
          <div className="mx-auto mx-5">
            <div className="flex">
              {/* Left */}
              <CustomImage
                src={profile.imageUrl}
                className="object-fit w-[7rem] h-[7rem] rounded-full"
                alt="User image"
              />
              {/* Right */}
              <div className="ml-5">
                <div className="text-gray-700">
                  <div className="flex flex-wrap">
                    {Object.entries(keys).map(([field, value]: any) => {
                      if (field == "firstName") {
                        return (
                          <div key={field} className="mr-6">
                            <p className="text-royalBlue text-xs font-semibold my-2">
                              {value}
                            </p>
                            <p className="text-royalBlue text-base">
                              {profile?.firstName + " " + profile?.lastName}
                            </p>
                          </div>
                        );
                      }
                      if (field === "signPermission") {
                        return (
                          <div key={field} className="mr-6">
                            <p className="text-royalBlue text-xs font-semibold my-2">
                              {value}
                            </p>
                            <p className="text-royalBlue text-base">
                              {profile?.sof?.id ? "Yes" : "No"}
                            </p>
                          </div>
                        );
                      }
                      if (field === "state") {
                        return (
                          <div key={field} className="mr-6">
                            <p className="text-royalBlue text-xs font-semibold my-2">
                              {value}
                            </p>
                            <p className="text-royalBlue text-base">
                              {states &&
                                states.length > 0 &&
                                states.find(
                                  (state: any) =>
                                    state?.state_code === profile[field]
                                )?.name
                                ? states.find(
                                  (state: any) =>
                                    state?.state_code === profile[field]
                                )?.name
                                : "---"}
                            </p>
                          </div>
                        );
                      }
                      if (field === "roles" && profile?.roles?.length > 1) {
                        return (
                          <div key={field} className="mr-6">
                            <p className="text-royalBlue text-xs font-semibold my-2">
                              {value}
                            </p>
                            {/* <p className="text-royalBlue text-base">{profile?.roles[0] + ', ' + (profile?.roles[1]?.includes('Physician') && 'Clinician')}</p> */}
                            <p className="text-royalBlue text-base">
                              {profile?.roles &&
                                physicianToClinician(profile?.roles)?.map(
                                  (role: string, index: number) =>
                                    `${role}${index + 1 !== profile?.roles?.length
                                      ? ", "
                                      : ""
                                    }`
                                )}
                            </p>
                          </div>
                        );
                      }
                      return (
                        <div key={field} className="mr-6">
                          <p className="text-royalBlue text-xs font-semibold my-2">
                            {value}
                          </p>
                          <p className="text-royalBlue text-base">
                            {profile[field]
                              ? profile[field]?.includes("Physician")
                                ? "Clinician"
                                : profile[field]
                              : "---"}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center">
                    <Button
                      btnType="transparent"
                      onClick={changePassword}
                      title={"Change Password"}
                      className={"my-3 hover:text-[#78dbdc]"}
                    />
                    {profile?.roles?.includes("Physician") && (
                      <div className="mx-3 flex items-center">
                        {profile?.sof?.id ? (
                          <DropdownSOF
                            options={[
                              { label: "Update", labelValue: "update" },
                              { label: "View", labelValue: "view" },
                              { label: "Delete", labelValue: "delete" },
                            ]}
                            getValueOfSof={(value: any) => {
                              if (
                                value === "update" &&
                                profile.sof?.isUpdated
                              ) {
                                return SOFClick({
                                  type: "update-req-pending",
                                  item: profile.sof,
                                });
                              }
                              SOFClick({ type: value, item: profile.sof });
                            }}
                            text="Signature on File (SOF) Actions"
                            expiryDate={profile.sof.expiryDate}
                          // disabled={item?.data?.SOF?.status.toLowerCase() == 'pending'}
                          />
                        ) : (
                          // <RequiredFieldTooltip required={true} tooltipText='An active signature on file will allow authorized office staff to order specific Castle tests on behalf of the clinician.'>
                          <RequiredFieldTooltip
                            required={true}
                            tooltipText="New SOF (signature on file) creation has been disabled. Please use Clinician Approval workflow (option B in Ordering Instructions Guide) and reach out if you have any questions."
                          >
                            <Button
                              title="Create Signature on File (SOF)"
                              btnType="transparent"
                              className="ml-2.5"
                              onClick={(e) => {
                                e.stopPropagation();
                                SOFClick({ type: "create", item: profile.sof });
                              }}
                              disabled
                            />
                          </RequiredFieldTooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {profile?.roles?.includes("Collaborative Order Agreement") && (
                <Fragment>
                  <div className="text-royalBlue font-semibold mt-5 mb-5">
                    Associated Order Agreement
                  </div>
                  <div className="flex flex-wrap min-w-full 2xl:mb-0 xl:mb-0 lg:mb-12">
                    {profile && (
                      <AssociatedPathLabAndRolesTable
                        tableData={orderAgreementTable}
                        roles={profile?.roles}
                        className={""}
                      />
                    )}
                  </div>
                </Fragment>
              )}
              {profile?.roles?.includes?.("Physician") && (
                <div className="w-full border-t border-b border-roundedInputBorder py-6 px-5 mt-7">
                  <p className="font-semibold">E-Signature</p>
                  <p className="font-normal my-3.5">
                    E-signature is required for order authorization.
                  </p>

                  <div className="flex justify-center items-center bg-[#F9F9FA] border border-roundedInputBorder rounded-lg w-[209px] h-[123px]  p-4 mb-3">
                    <CustomImage
                      alt="Signature"
                      // src="https://plus.unsplash.com/premium_photo-1661482843558-f16df35e432c?q=80&w=1954&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      src={signatureData?.viewableURL}
                      className="h-full w-full object-cover"
                    />
                  </div>

                  <div className="w-full flex justify-start items-center">
                    <Button
                      title={
                        signatureId ? "Change Signature" : `Create Signature`
                      }
                      btnType="transparent"
                      className="font-semibold text-primary"
                      disabled={deletingSignature}
                      onClick={() => setShowSignaturePopup(true)}
                    />
                    {!!signatureId && <Button
                      title={
                        "Delete Signature"
                      }
                      btnType="transparent"
                      className="font-semibold text-requiredRed mx-4"
                      disabled={deletingSignature || creating || !!signatureId === false}
                      submitting={deletingSignature}
                      onClick={() => setShowDeletePopup(true)}
                    />}
                  </div>
                </div>
              )}

              <CustomPopup
                visible={showSignaturePopup}
                hidePopup={() => hidePopup()}
                hideButtons
                popUpTitle="E-Signature"
                popUpSubtitle="E-signature is required for order authorization"
              >
                <CustomSignatureInput
                  submitting={creating}
                  handleSubmission={handleSubmission}
                />
              </CustomPopup>
              {profile?.roles?.includes?.("Physician") && <CustomPopup
                visible={showDeletePopup}
                hidePopup={() => setShowDeletePopup(false)}
                buttons={[
                  {
                    disabled: deletingSignature,
                    onClick: () => setShowDeletePopup(false),
                    title: 'No',
                    type: "secondary"
                  },
                  {
                    disabled: deletingSignature,
                    submitting: deletingSignature,
                    onClick: () => handleDeleteSignature(Number(signatureId)),
                    title: 'Yes'
                  },

                ]}
                popUpTitle="E-Signature"
              >
                <p className="font-sans text-requiredRed py-2">Are you sure you want to delete the E-signature ?</p>
              </CustomPopup>}

              <div className="text-royalBlue font-semibold mt-5 mb-5">
                Associated Organizations & Roles
              </div>
              <AssociatedOrganisationsAndRolesTable
                tableData={tableData}
                roles={profile.roles}
              />
            </div>
            {profile?.roles?.includes("Physician") && (
              <div>
                <div className="text-royalBlue font-semibold mt-5 mb-5">
                  User Signature on Files
                </div>
                <UserSOFTable
                  tableData={sofTable}
                  className=" border-t pb-16"
                />
              </div>
            )}
          </div>
          <SOFFlow
            isVisible={sofState.sofVisible}
            type={sofState.sofType}
            item={sofState.item}
            closeSOF={() =>
              setSofState({ sofType: "create", item: null, sofVisible: false })
            }
          />
        </motion.div>
      </AnimatePresence>
    </ProfileScreenLayout>
  );
}
const mapStateToProps = (state: any) => ({
  profile: state.Profile.profile,
  tableData: state.Profile.tableData,
  orderAgreementTable: state.Profile.orderAgreementTable,
  sofTable: state.Profile.sofTable,
  error: state.Profile.error,
  fetching: state.Profile.fetching,
});

const mapDispatchToProps = {
  fetchProfile: ProfileActions.fetchprofile,
  fetchOrderAgreementOfUser: ProfileActions.fetchOrderAgreementOfUser,
  setButtonState: ButtonStateActions.setButtonState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHomeScreen);
