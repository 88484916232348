/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import NoImageIcon from "../../assets/svg/NoImageIcon";
import OrganizationNoImage from "../../assets/svg/OrganizationNoImage";
import CustomInput from "../global/CustomInput";
import OrganizationDetailLoadingIndicator from "../global/LoadingIndicators/OrganizationDetailLoadingIndicator";
import { updateSelectedOrganization } from "../../Store/Actions/OnlineOrderFormActions";
import dataConstants from "../../constants/dataConstants";
import "../../assets/css/custom.css";
import OrganizationActions from "../../Store/Actions/OrganizationsActions";
import toastMessages from "../../utils/helpers/toastMessage";
import { useOktaAuth } from "@okta/okta-react";
import ToggleSwitch from "../atomic/ToggleSwitch";
import RequiredFieldTooltip from "../global/RequiredFieldTooltip";

function DetailHolder({ label, value }: any): JSX.Element {
  return (
    <div className="my-3 mr-8">
      <p className={"self-center text-sm font-semibold pb-1"}>{label}</p>
      <p className="text-royalBlue text-base font-normal break-all">
        {!!value ? value : "---"}
      </p>
    </div>
  );
}
function OrganizationDetail({ id }: any): JSX.Element {
  const dispatch: any = useDispatch();
  const { authState } = useOktaAuth();
  const [detail, setDetail] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { data, fetching, error } = useSelector(
    (state: any) => state.Organizations
  );
  const { data: states, error: statesError } = useSelector(
    (state: any) => state.StateAndCity
  );

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [listHeight, setListHeight] = useState(
    window.innerHeight - window.innerHeight * 0.22
  );
  const setDimension = () => {
    if (window.innerHeight <= 370) {
      setListHeight(window.innerHeight - window.innerHeight * 0.5);
    } else if (window.innerHeight <= 400) {
      setListHeight(window.innerHeight - window.innerHeight * 0.48);
    } else if (window.innerHeight <= 450) {
      setListHeight(window.innerHeight - window.innerHeight * 0.42);
    } else if (window.innerHeight <= 480) {
      setListHeight(window.innerHeight - window.innerHeight * 0.4);
    } else if (window.innerHeight <= 500) {
      setListHeight(window.innerHeight - window.innerHeight * 0.38);
    } else if (window.innerHeight <= 530) {
      setListHeight(window.innerHeight - window.innerHeight * 0.36);
    } else if (window.innerHeight <= 570) {
      setListHeight(window.innerHeight - window.innerHeight * 0.34);
    } else if (window.innerHeight <= 600) {
      setListHeight(window.innerHeight - window.innerHeight * 0.32);
    } else if (window.innerHeight <= 630) {
      setListHeight(window.innerHeight - window.innerHeight * 0.3);
    } else if (window.innerHeight <= 655) {
      setListHeight(window.innerHeight - window.innerHeight * 0.29);
    } else if (window.innerHeight <= 680) {
      setListHeight(window.innerHeight - window.innerHeight * 0.28);
    } else if (window.innerHeight <= 700) {
      setListHeight(window.innerHeight - window.innerHeight * 0.27);
    } else if (window.innerHeight <= 715) {
      setListHeight(window.innerHeight - window.innerHeight * 0.27);
    } else if (window.innerHeight <= 750) {
      setListHeight(window.innerHeight - window.innerHeight * 0.26);
    } else if (window.innerHeight <= 800) {
      setListHeight(window.innerHeight - window.innerHeight * 0.24);
    } else if (window.innerHeight <= 850) {
      setListHeight(window.innerHeight - window.innerHeight * 0.22);
    } else if (window.innerHeight <= 880) {
      setListHeight(window.innerHeight - window.innerHeight * 0.22);
    } else if (window.innerHeight <= 900) {
      setListHeight(window.innerHeight - window.innerHeight * 0.21);
    } else if (window.innerHeight <= 980) {
      setListHeight(window.innerHeight - window.innerHeight * 0.196);
    } else if (window.innerHeight <= 1030) {
      setListHeight(window.innerHeight - window.innerHeight * 0.28);
    } else if (window.innerHeight <= 1050) {
      setListHeight(window.innerHeight - window.innerHeight * 0.1);
    } else {
      setListHeight(window.innerHeight - window.innerHeight * 0.2);
    }
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    // calling to initially set list height on basis of screen height
    setDimension();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      OrganizationActions.getOrganizationDetail({
        data: {
          id,
        },
        onError: (message: string) => toastMessages.error(message),
        onSuccess: (organization: any) => {
          setDetail(organization);
          setLoading(false);
        },
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
      })
    );
  }, [id]);

  const history = useHistory();
  function openUsers(): void {
    dispatch(
      updateSelectedOrganization(data.find((item: any) => item.id == detail.id))
    );
    history.push(`/manage/portal-users/${detail.id}`);
  }
  if (fetching || loading) {
    return <OrganizationDetailLoadingIndicator />;
  }

  if (!fetching && !loading && error) {
    return (
      <div className="px-12 h-full pb-[45%] lg:pb-[35%] xl:pb-[25%] 2xl:pb-0">
        <p>Something went wrong...</p>
      </div>
    );
  }

  return (
    <div
      className=" px-12 overflow-auto overflownewScroller"
      style={{ height: listHeight }}
    >
      <p className="text-sm font-semibold mt-1 mb-3">Logo</p>
      {detail && detail?.logoUrl ? (
        <img
          className="h-24 w-4/6 lg:w-3/6 object-fit my-4 rounded"
          src={detail?.logoUrl}
        />
      ) : (
        <div className="h-24 w-4/6 lg:w-3/6 rounded my-4">
          <OrganizationNoImage />
        </div>
      )}
      <DetailHolder
        label={"Organization Name"}
        value={detail && detail?.name}
      />
      {/* <DetailHolder label={'Description'} value={detail && detail?.profile?.description ? detail?.profile?.description : '---'} /> */}
      <DetailHolder
        label={"Nickname"}
        value={detail && detail?.description ? detail?.description : "---"}
      />
      <div className="flex items-center">
        {/* <DetailHolder label={'Organization Email ID'} value={detail && detail?.email} /> */}
        <DetailHolder label={"Phone"} value={detail && detail?.phone} />
        <DetailHolder label={"Fax"} value={detail && detail?.fax} />
      </div>
      <DetailHolder label={"Address"} value={detail && detail?.address} />

      <DetailHolder label={"Address 2"} value={detail && detail?.address2} />
      <div className="flex items-center">
        <DetailHolder label={"City"} value={detail && detail?.city} />
        <DetailHolder label={"ZIP Code"} value={detail && detail?.zipcode} />
        {/* <DetailHolder label={'State/Province'} value={detail && detail?.profile?.state} /> */}
        <DetailHolder
          label={"State/Province"}
          value={
            detail && detail?.state
              ? states &&
                states.length > 0 &&
                states?.find((state: any) =>
                  `${state.name} ${state.state_code}`?.includes(detail?.state)
                )?.name
              : detail?.state
          }
        />
      </div>

      <p className="text-sm font-semibold mt-5 mb-3">
        Castle Biosciences Test Services
      </p>
      <Formik
        initialValues={{
          scc: true,
          um: true,
          cm: true,
          tc: true,
          cdo: true,
          idg: true,
        }}
        onSubmit={() => {}}
        render={({ errors, touched }) => (
          <div className="flex flex-col">
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className={
                "flex flex-row md:flex-wrap xl:flex-nowrap 2xl:flex-nowrap lg:flex-nowrap my-2 overflow-auto overflow-x-hidden"
              }
            >
              <div className="w-1/3 xl:mr-0 lg:mr-12 2xl:mr-0 md:mr-8">
                <p className="text-sm mb-2 whitespace-nowrap">
                  PROGNOSTIC TESTS
                </p>
                {dataConstants.OrganizationSignUpFormTestTypeCheckboxes.checkboxes.map(
                  (checkbox, index) => (
                    <CustomInput
                      id={checkbox.name}
                      key={checkbox.name}
                      name={checkbox.name}
                      label={checkbox.label}
                      checked={
                        detail &&
                        detail?.cbsTests?.includes(
                          checkbox?.name?.toUpperCase()
                        )
                      }
                      showToolTip
                      tooltipText={checkbox.tooltip}
                      required={false}
                      placeholder={""}
                      errors={null}
                      touched={null}
                      type="checkbox"
                      className="my-1"
                      isPage
                      disabled
                    />
                  )
                )}
              </div>
              <div className="w-1/3 tab:ml-8">
                <p className="text-sm mb-2 whitespace-nowrap">
                  DIAGNOSTIC TESTS
                </p>
                {
                  <CustomInput
                    id={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox2.name
                    }
                    key={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox2.name
                    }
                    name={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox2.name
                    }
                    label={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox2.label
                    }
                    checked={
                      detail &&
                      detail &&
                      detail?.cbsTests?.includes(
                        dataConstants?.OrganizationSignUpFormTestTypeCheckboxes?.checkbox2?.name?.toUpperCase()
                      )
                    }
                    required={false}
                    showToolTip
                    tooltipText={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox2.tooltip
                    }
                    placeholder={""}
                    errors={null}
                    touched={null}
                    type="checkbox"
                    isPage
                    disabled
                  />
                }
              </div>
              <div className="w-1/2 md:mt-2 2xl:mt-0 xl:mt-0 lg:mt-0">
                <p className="text-sm mb-2 whitespace-nowrap">
                  THERAPY SELECTION TESTS
                </p>
                {
                  <CustomInput
                    id={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox3.name
                    }
                    key={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox3.name
                    }
                    name={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox3.name
                    }
                    label={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox3.label
                    }
                    checked={
                      detail &&
                      detail?.cbsTests?.includes(
                        dataConstants.OrganizationSignUpFormTestTypeCheckboxes.checkbox3.name.toUpperCase()
                      )
                    }
                    required={false}
                    placeholder={""}
                    showToolTip
                    tooltipText={
                      dataConstants.OrganizationSignUpFormTestTypeCheckboxes
                        .checkbox3.tooltip
                    }
                    errors={null}
                    touched={null}
                    type="checkbox"
                    isPage
                    disabled
                  />
                }
              </div>
            </div>
            <div className="w-full  block">
              <p className="text-sm mt-5 mb-3">Allow Email Alerts</p>
              
    <RequiredFieldTooltip required={true} tooltipText={'Click edit to update these settings'}>
              <ToggleSwitch
                name={'isEmailEnabled'}
                orgDetailDisabled={true}
                id="switch"
                checked={detail && detail?.isEmailEnabled}
                handleChange={() => {}}
              />
              </RequiredFieldTooltip>
              {/* <CustomInput
                id={"isEmailEnabled"}
                key={"isEmailEnabled"}
                name={"isEmailEnabled"}
                label={detail && detail?.isEmailEnabled ? "Yes" : "No"}
                checked={detail && detail?.isEmailEnabled}
                showToolTip
                tooltipText={"Allow Email Alerts"}
                required={false}
                placeholder={""}
                errors={null}
                touched={null}
                type="checkbox"
                className="my-1"
                isPage
                disabled
              /> */}
            </div>
          </div>
        )}
      />
      <p className="text-sm font-semibold mt-5 mb-3">
        Total Number of Portal Users in this Organization{" "}
        {detail && detail?.users && (
          <span>
            {Array.isArray(detail.users) ? detail?.users?.length : detail.users}
          </span>
        )}
      </p>
      <button
        type="button"
        onClick={openUsers}
        className=" text-[#18b5b7] font-semibold text-xs m-0 p-0 lg:text-base uppercase pb-3"
      >
        see users
      </button>
    </div>
  );
}

export default OrganizationDetail;
