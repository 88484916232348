/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/indent */

import UpdateSettingsPayload from "../../models/updateSettings.model";
import UpdateOrganizationSettingsPayloadItem from "../../models/notificationsModels/updateOrganizationSettingsPayloadItem";
import updateTypeSettingsPayloadItem from "../../models/notificationsModels/updateTypeSettingsPayloadItem";
import requests from "../../services/http";
import { AvailableTimePayload } from "../../Pages/Admin/Shipments/Pickup/pickUpInformation";
const http = {
 postSignup: async (url: string, data: any): Promise<any> =>
  await requests.post(url, data),
 validateEmail: async (url: string, data: any): Promise<any> =>
  await requests.post(url, data),
 getOrganizations: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getOrganizationUsers: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getPortalUsers: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getPortalUsersByPathLabID: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getSinglePortalUser: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.put(url, body, config),
 createOrganization: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 updateOrganization: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.put(url, body, config),
 getStateAndCity: async (url: string): Promise<any> => await requests.get(url),
 getSuffix: async (url: string): Promise<any> => await requests.get(url),
 getSpeciality: async (url: string): Promise<any> => await requests.get(url),
 getPresignedUrl: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 inviteUser: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 getPortalUserById: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getOrderAgreement: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getUsersListRole: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 updateUserProfile: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 getProfile: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 updateStatus: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 getTestsAndICDCode: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getPatients: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 checkPendingOrders: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 generateSignedLetter: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 createOnlineOrder: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 updateOnlineOrder: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 retryOnlineOrder: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 getInsuranceCompany: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getLabById: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getAllLabs: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getOrderById: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getTableData: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getActiveLabelsData: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getDashboardData: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 authorizeOrders: async (url: string, config: any, body: any): Promise<any> =>
  await requests.put(url, body, config),
 addDocuments: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 authorizePDFDocuments: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 getReport: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getPDF: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 downloadMultiplePDF: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 createPDFOrder: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 addNotes: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 deleteFileFromAzure: async (url: string, config: any): Promise<any> =>
  await requests.delete(url, config),
 createSOF: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 updateSOF: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 getSOF: async (url: string, body: any, config: any): Promise<any> =>
  requests.post(url, body, config),
 getPathlabs: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 viewSOF: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 fetchSyncStatus: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 getAllowedTests: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getFAQs: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getDocuments: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getVideos: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getHelp: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 searchHelp: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getActiveTests: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 getMultipleOrders: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 viewDocuments: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 viewSofs: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 getSettings: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 updateSettings: async (
  url: string,
  body: UpdateSettingsPayload,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 getNotificationMedium: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 getOrganizationNotificationMedium: async (
  url: string,
  config?: any
 ): Promise<any> => await requests.get(url, config),
 saveOrganizationNotificationsSettings: async (
  url: string,
  body: {
   data: UpdateOrganizationSettingsPayloadItem[];
  },
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 saveNotificationTypeSettings: async (
  url: string,
  body: {
   data: updateTypeSettingsPayloadItem[];
  },
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 getUserNotificationMedium: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 getUserNotificationType: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 getAllNotifications: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getUnreadCount: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getOrganizationDetail: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 addAdditionalClinician: async (
  url: string,
  body: any,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 detectIcdMismatch: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 detectMedicationMismatch: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 getMedications: async (url: string, config?: any): Promise<any> =>
  await requests.get(url, config),
 postActiveLabels: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 disassociateLabel: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 associateLabel: async (
  url: string,
  body: { orderIds: number[]; fedexLabelId: number },
  config: any
 ): Promise<any> => await requests.put(url, body, config),
 fetchAvailableTimeOptions: async (
  url: string,
  body: AvailableTimePayload,
  config: any
 ): Promise<any> => await requests.post(url, body, config),
 getPickupsData: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 schedulePickup: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 cancelPickup: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 discardOrder: async (url: string, config: any): Promise<any> =>
  await requests.delete(url, config),
 checkSignature: async (url: string, config: any): Promise<any> =>
  await requests.get(url, config),
 createSignature: async (url: string, body: any, config: any): Promise<any> =>
  await requests.post(url, body, config),
 updateSignature: async (url: string, body: any, config: any): Promise<any> =>
  await requests.put(url, body, config),
 deleteSignature: async (url: string, body?: any, config?: any): Promise<any> =>
  await requests.patch(url, body, config),
};

export default http;
