import './styles.css'
interface ToggleSwitchProps {
  name: string
  id: string | number
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  checked: boolean
  disabled?: boolean
  containerClassName?: string
  orgDetailDisabled?: boolean;
}
function ToggleSwitch({ name, id, handleChange, checked, disabled, containerClassName,orgDetailDisabled }: ToggleSwitchProps): JSX.Element {
  return <label className={`switch ${orgDetailDisabled && 'orgDetailDisabled' }`}>
    <input
      type="checkbox"
      disabled={disabled || orgDetailDisabled}
      onChange={handleChange}
      checked={checked}
    />
    <span className="slider round"></span>
  </label>
}

export default ToggleSwitch