import CustomImage from "./global/CustomImage";
import Button from "./atomic/Button";

function ExistingSignaturePopup({
  imageURL,
  handleChange,
  handleDone,
  children
}: {
  imageURL: string;
  handleChange: Function;
  handleDone: Function;
  children?: any
}): JSX.Element {
  return (
    <div>
      {children}
      <div className="h-[177px] w-[550px] bg-sideNavGray border border-roundedInputBorder rounded-lg mb-6 flex justify-center items-center">
        <CustomImage
          alt="signature preview"
          className="h-full w-full object-cover"
          src={imageURL}
        />
      </div>

      <div className="flex items-center justify-end">
        <Button
          btnType="secondary"
          title={"Change"}
          className={`font-semibold mr-3`}
          onClick={() => handleChange()}
        />

        <Button
          btnType="primary"
          title={"Done"}
          className={`font-semibold`}
          onClick={() => handleDone()}
        />
      </div>
    </div>
  );
}

export default ExistingSignaturePopup;
