import axios from "axios";
import fileDownload from "js-file-download";
import { instance as axiosInstance } from "../../services/http";
import { BlockBlobClient } from "@azure/storage-blob";
import toastMessages from "./toastMessage";
interface PresignedUrlArgs {
  type: "image" | "document" | "images/esignatures";
  token: string;
  file: File;
}

interface pdfNameAndIds {
  id: number;
  fileName: string;
}

class FileActions {
  private _getExt(filename: string) {
    const ext = filename.split(".").pop();
    if (ext == filename) return "";
    return ext;
  }

  downloadFile({
    url,
    filename,
  }: {
    url: string;
    filename: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          resolve(fileDownload(response.data, filename));
        })
        .catch((error) => reject(error));
    });
  }

  async viewReports({
    id,
    token,
  }: {
    id: number;
    token: string;
  }): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      if (!!token === false)
        reject(new Error("Not authorized to perform the action"));

      try {
        const response = await axiosInstance.get(
          `/v1/orders/${id}/new-flow/get-report`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (
          response.status === 200 &&
          response.data.data.reportUrl?.length > 0
        ) {
          resolve(response?.data?.data?.reportUrl);
        } else reject(new Error("No report present to download"));
      } catch (error) {
        reject(error);
      }
    });
  }

  async downloadReport({
    id,
    token,
    fileName,
  }: {
    id: number;
    token: string;
    fileName: string;
  }) {
    return new Promise(async (resolve, reject) => {
      if (!!token === false)
        reject(new Error("Not authorized to perform the action"));

      try {
        const response = await axiosInstance.get(
          `/v1/orders/${id}/new-flow/get-report`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (
          response.status === 200 &&
          response.data.data.reportUrl?.length > 0
        ) {
          Promise.allSettled(
            response.data.data.reportUrl.map((url: string, index: number) =>
              this.downloadFile({
                url,
                filename: `${fileName}${index + 1} Report.pdf`,
              })
            )
          )
            .then((results) => resolve(results))
            .catch((error) => reject(error));
        } else reject(new Error("No report present to download"));
      } catch (error) {
        reject(error);
      }
    });
  }

  async downloadMultiplePDF(
    data: Array<{ id: number; fileName: string }>,
    token: string
  ) {
    try {
      let orderIds = data.map((item: any) => item.id);

      const response: any = await axiosInstance.post(
        `v1/orders/new-flow/multiple-download`,
        { orderIds },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response?.data?.length > 0) {
        // fileDownload(response, `${data[0].fileName}.pdf`);
        response?.data?.map((item: any, index: number) =>
          fileDownload(
            new Blob([new Uint8Array(item.data).buffer], {
              type: "application/pdf",
            }),
            `${data[index].fileName}.pdf`
          )
        );
      }
    } catch (error: any) {
      // console.log(error);
      toastMessages.error(error);
    }
  }

  private async _getPresignedUrl({
    type,
    token,
    file,
  }: PresignedUrlArgs): Promise<{
    url: string;
    filepath: string;
    file: File;
  }> {
    return new Promise((resolve, reject) => {
      const url = `v1/pre-signed-url/${
        type === "image" || type === "images/esignatures"
          ? "images"
          : "documents"
      }/${file.name.replace(/ /g, "_")}`;

      axiosInstance
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.url && response.data.filepath) {
            resolve({
              url: response.data.url,
              filepath: response.data.filepath,
              file,
            });
          } else reject(new Error("Could not generate URL"));
        })
        .catch((error) => reject(error));
    });
  }

  async uploadToAzure({
    type,
    file,
    token,
  }: PresignedUrlArgs): Promise<string> {
    return new Promise((resolve, reject) => {
      this._getPresignedUrl({
        type,
        token,
        file,
      })
        .then((response) => {
          const blockBlobClient = new BlockBlobClient(response.url);

          blockBlobClient
            .uploadData(file, {
              blobHTTPHeaders: { blobContentType: file.type },
            })
            .then((res) => resolve(response.filepath))
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  }

  convertBase64ToImage(
    url: string,
    fileName: string
  ): Promise<File | undefined> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], fileName, { type: "image/png" });
          resolve(file);
        })
        .catch((error) => reject(new Error(error.message)));
    });
  }
}

export default FileActions;
