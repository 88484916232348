import { useOktaAuth } from '@okta/okta-react'
import { debounce } from 'lodash'
import { createRef, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import FilterIcon from '../../assets/svg/FilterIcon'
import PageBack from '../../assets/svg/PageBack'
import PageForward from '../../assets/svg/PageForward'
import SelectedCheckMark from '../../assets/svg/SelectedCheckMark'
import http from '../../Store/API/HttpMethods'
import orderResponseFormatter from '../../utils/helpers/orderResponseFormatter'
import pdfHelper from '../../utils/helpers/pdfHelper'
import useQuery from '../../utils/helpers/QueryHelper'
import toastMessages from '../../utils/helpers/toastMessage'
import DataTable from '../DataTable/DataTable'
import { header, ITableHeaderProps } from '../DataTableHeader/DataTableHeader'
import TableLoadingIndicator from '../global/LoadingIndicators/TableLoadingIndicator'
import OnlineOrderPDF, { printRef } from '../organism/OnlineOrderForm/OnlineOrderPDF'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import fileDownloader from '../../utils/helpers/fileDownloader'
import DownloadHelper from '../../utils/helpers/fileDownloader'
import Button from '../atomic/Button'
import LoadingSvg from '../../assets/svg/LoadingSVG'
import TableFilterDropdown from './TableFilterDropdown'
import pageStyle from '../../constants/pageStyle'
import { suggestion } from '../organism/SuggstionsDropdown'
import dataConstants from '../../constants/dataConstants'
import physicianToClinician from '../../utils/helpers/physicianToClinician'
import PDFPrint from './PDFPrint'
import SettingsActions from '../../Store/Actions/SettingsActions'
import TestOrdersProps from '../../models/testordersprops.model'
import SettingsItem from '../../models/settingsresponse.model'


function RenderButton({ type, onClick, disabled }: { type: string, onClick: any, disabled: boolean }) {

  if (type === 'forward')
    return <button onClick={onClick} disabled={disabled} className={'mr-2 disabled:cursor-not-allowed '}>
      <PageForward
        stroke={disabled ? '#b1b8c3' : '#172B4D'}
      />
    </button>
  return <button disabled={disabled} className={'ml-4 mr-6 disabled:cursor-not-allowed '} onClick={onClick}>
    <PageBack
      stroke={disabled ? '#b1b8c3' : '#172B4D'}
    />

  </button>
}

const itemsPerPage = [15, 20]

export function Pagination({ paginationData, handleBack, handleNext, size, submitting, handleItemsPerPage }: any): JSX.Element {
  function renderCount() {
    let count = ''
    if (paginationData.totalPages === 0) {
      count = '0'
    } else if (paginationData.totalPages === 1) {
      count = '0'
    } else if (paginationData.currentPage === paginationData.totalPages) {
      count = paginationData.totalItems
    } else {
      count = ((paginationData.currentPage) * size).toString()
    }
    return count
  }
  const startIndex = (paginationData.currentPage - 1) * size;
  const endIndex = paginationData.currentPage * size;
  return <div className='flex items-center justify-end orderTable:mr-[90px] mr-16 my-3'>
    {submitting === true && <LoadingSvg isSecondary className="animate-spin mr-3" />}
    <div className='mx-2 flex justify-between items-center'>
      <p className='text-xs text-[#647188] mx-2'>Records Per Page</p>
      <div className='!m-0 !p-0 border rounded-md overflow-hidden'>
        {itemsPerPage.map((itemSize) => <button
          type='button'
          onClick={() => handleItemsPerPage(itemSize)}
          className={`${size == itemSize ? 'bg-primary text-white' : 'bg-white text-[#707070]'} ${itemSize !== 50 ? 'border-r' : ''} w-[28px] h-[28px] inline-block`}>
          <p className='text-xs '>{itemSize}</p>
        </button>)}
      </div>
    </div>
    <p className='!text-xs text-royalBlue'>
      {paginationData.currentPage === 1 ? startIndex + 1 : startIndex}-{endIndex > paginationData.totalItems ? paginationData.totalItems : endIndex} <span> of </span> <span>{paginationData.totalItems}</span>
    </p>
    <RenderButton type='back' onClick={handleBack} disabled={submitting || paginationData.currentPage === 1 || paginationData.totalPages === 1} />
    <RenderButton type='forward' onClick={handleNext} disabled={(submitting || paginationData.currentPage) === paginationData.totalPages || paginationData.totalPages === 0} />
  </div>
}

function TestOrdersTable({
  hideButtons,
  setButtonsDisabled,
  screen,
  buttonRefs,
  multiPrinting,
  setMultiPrinting,
  setCheckedIds,
  setCheckedIdTests,
  setPdfIds,
  setAllPdfIds,
  setButtonRefs,
  multiPrintRef,
  setCheckedPhysicianIds,
  className,
  settings,
  updatingSettings,
  fedexLabelId,
  fetchSettings,
  updateOrderTableSettings,
  refetch
}: TestOrdersProps) {
  const { authState } = useOktaAuth()
  const [size, setSize] = useState(15)
  const query: any = useQuery()
  const history = useHistory()
  const [fetching, setFetching] = useState<boolean>(true)
  const [searching, setSearching] = useState<boolean>(false)
  const [fetchingPage, setFetchingPage] = useState<boolean>(false)
  const [elRefs, setElRefs] = useState<any>([]);
  const { fetching: loading, error, data: organizations, fetchingOrgUsers } = useSelector((state: any) => state.Organizations)
  const { data: pathLabs } = useSelector((state: any) => state.PathLab)
  const { selectedPhysician, selectedOrganization, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPathLab = profile?.roles?.includes('Collaborative Order Agreement') || profile?.roles?.includes('PathLab User')
  let isPLU = profile?.roles?.includes('PathLab User')
  const [fields, setFields] = useState<any[]>([])
  const [groupByFilters, setGroupByFilters] = useState<any[]>(dataConstants.testOrdersGroupColumnsList)
  const groupFilterKey = useRef<string>('')
  const initialColumns: header[] = [
    {
      name: 'Patient First Name',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Patient Last Name',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Patient DOB',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'Order Type',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false,
      suggestions: [{ label: 'Online Order', value: 'Online Order' }, { label: 'EMR Order', value: 'EMR Order' }, { label: 'Paper TRF', value: 'Paper TRF' }, { label: 'Fax / Email', value: 'Fax/Email' }]
    },
    {
      name: 'Test Type',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false,
      // suggestions: ['1| TC', '2| SCC', '4| UM', '5| DDM', '3| CM']
      suggestions: [
        { label: 'TC', value: '1' }, { label: 'SCC', value: '2' }, { label: 'UM', value: '4' }, { label: 'DDM', value: '5' }, { label: 'CM', value: '3' }, { label: 'IDG', value: '8' }]
    },
    {
      name: 'Castle ID',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false

    },
    {
      name: 'Specimen ID',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false

    },
    // {
    //   name: 'Org',
    //   preventAutoSearch: screen !== 'referral-orders' ? true : false,
    //   disableSuggestiveFiltering: false,
    //   filter: screen !== 'dashboard' ? true : false
    // },
    // {
    //   name: 'Clinician',
    //   preventAutoSearch: screen !== 'referral-orders' ? true : false,
    //   disableSuggestiveFiltering: false,
    //   filter: screen !== 'dashboard' ? true : false

    // },
    // {
    //   name: 'PathLab',
    //   preventAutoSearch: true,
    //   disableSuggestiveFiltering: false,
    //   filter: screen !== 'dashboard' ? true : false
    // },
    {
      name: 'Org',
      // preventAutoSearch: screen !== 'referral-orders' ? true : false,
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'Clinician',
      // preventAutoSearch: screen !== 'referral-orders' ? true : false,
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'PathLab',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'Placed By',
      preventAutoSearch: true,
      disableSuggestiveFiltering: false,
      filter: screen !== 'dashboard' ? true : false,
      suggestions: [
        { label: 'Office Manager', value: 'Office Manager' },
        { label: 'Clinician', value: 'Physician' },
        // { label: 'PathLab User', value: 'PathLab User' },
        { label: 'Collaborative Order Agreement', value: 'Collaborative Order Agreement' },
      ]
    },
    {
      name: 'Ordered Date',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false

    },
    {
      name: 'Report Date',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'Castle Result',
      preventAutoSearch: false,
      disableSuggestiveFiltering: false,
      filter: screen !== 'dashboard' ? true : false
    },
    {
      name: 'Status',
      preventAutoSearch: true,
      filter: (screen === 'test-orders' || screen === 'documents') ? true : false,
      disableSuggestiveFiltering: true,
      multiSelect: true,
      sticky: isPathLab || screen === 'unshipped-orders',
      stickyClass: (isPathLab || screen === 'unshipped-orders') && screen !== 'approvals' ? `${screen === 'unshipped-orders' ? 'right-[78px]' : 'right-[92px]'} shadow-leftShadow min-w-[10rem]` : '',
      suggestions: (screen === 'test-orders' || screen === 'documents' || screen === 'referral-orders') ? [
        {
          label: 'In Progress',
          value: 10,
          selected: false,
          key: 'status'
        },
        {
          label: 'Pending Submission',
          value: 20,
          selected: false,
          key: 'status'
        },
        {
          label: 'Incomplete',
          value: 25,
          selected: false,
          key: 'status'
        },
        {
          label: 'Pending Approval',
          value: 30,
          selected: false,
          key: 'status'
        },
        {
          label: 'Submitted',
          value: 40,
          selected: false,
          key: 'status'
        },
        {
          label: 'COA Order Attestation',
          value: 55,
          selected: false,
          key: 'status'
        },
        {
          label: 'Pending Receipt of Tissue',
          value: 60,
          selected: false,
          key: 'status'
        },
        {
          label: 'Pending Results',
          value: 70,
          selected: false,
          key: 'status'
        },
        {
          label: 'On Hold',
          value: 80,
          selected: false,
          key: 'status'
        },
        {
          label: 'Cancelled',
          value: 90,
          selected: false,
          key: 'status'
        },
        {
          label: 'Completed',
          value: 100,
          selected: false,
          key: 'status'
        }
      ] : screen === 'unshipped-orders' ? [{
        label: 'Submitted',
        value: 40,
        selected: false,
        key: 'status'
      },
      {
        label: 'Pending Receipt of Tissue',
        value: 60,
        selected: false,
        key: 'status'
      },
      {
        label: 'Pending Results',
        value: 70,
        selected: false,
        key: 'status'
      },
      {
        label: 'On Hold',
        value: 80,
        selected: false,
        key: 'status'
      },] : []
    },
    {
      name: 'Actions',
      sticky: isPathLab,
      stickyClass: isPathLab && screen !== 'approvals' ? 'right-0' : '',
      preventAutoSearch: true
    },
    {
      name: 'Label',
      sticky: true,
      stickyClass: 'right-0 bg-white',
      preventAutoSearch: true
    }
  ]
  const [header, setHeader] = useState<any>({
    hasVerticalMenu: false,
    onFilter(filters: any) {
      handleFilters(filters)
    },
    NavLink: '',
    nnProps: '',
    check: false,
    hideCheckBox: true,
    data: initialColumns,
    list: [{ name: 'name' },
    { email: 'email' }]
  })
  const [groupedData, setGroupedData] = useState<any>([])
  const [tableBody, setTableBody] = useState<any[]>([])
  const [paginationData, setPaginationData] = useState<any>(null)
  const [filters, setFilters] = useState<any>({})
  const [singlePrint, setSinglePrint] = useState<boolean>(false)
  const [tableFilters, setTableFilters] = useState<any>({})
  const [arrangeColumns, setArrangeColumns] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [valueinFilter, setInfilter] = useState<boolean>(false)
  const [printIds, setPrintIds] = useState<string[]>([])
  const orderTableSettings = settings.find((setting: SettingsItem) => setting.type === "ORDER_TABLE_FIELDS")
  const apiCalled = useRef(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  function insert(arr: any[], index: number, newItem: any) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ]
  }
  function groupBy(list: any, keyGetter: any) {
    const map = new Map()
    list.forEach((item: any) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }
  const debounceSearch = useCallback(debounce(search, 500), [selectedOrganization?.id, selectedPhysician?.id, selectedPhysician, selectedOrganization])
  function search(apiFilters: any) {
    getTableData({ size, page: 1, status: screen === 'approvals' ? [30] : screen === 'dashboard' ? [85] : screen === 'documents' ? [95] : '', ...apiFilters, })
  }
  function handleFilters(filters: any) {
    let apiFilters: any = {
      organizationId: selectedOrganization?.id && screen !== 'referral-orders' ? selectedOrganization?.id : '',
      physicianId: selectedPhysician?.id && screen !== 'referral-orders' ? selectedPhysician?.id : '',
      pathlabId: selectedPathLab?.id && screen !== 'referral-orders' ? selectedPathLab?.id : ''
    }

    if (!!fedexLabelId || fedexLabelId === 0) {
      apiFilters.fedexLabelId = fedexLabelId
    }

    let keys: any = {}
    if (screen === 'unshipped-orders') {
      keys = {
        // patientName: 'patientName',
        patientFirstName: 'patientFirstName',
        patientLastName: 'patientLastName',
        patientDOB: 'patientDOB',
        testTypeCode: 'testTypeCode',
        specimenId: 'specimenId',
        organizationId: 'organizationId',
        physicianId: 'physicianId',
        orderDate: 'orderDate',

        // receivedDate: 'receivedDate',
        status: 'status',
      }
    } else if (isPathLab) {
      keys = {
        // patientName: 'patientName',
        patientFirstName: 'patientFirstName',
        patientLastName: 'patientLastName',
        patientDOB: 'patientDOB',
        orderType: 'orderType',
        testTypeCode: 'testTypeCode',
        castleId: 'castleId',
        specimenId: 'specimenId',
        organizationId: 'organizationId',
        physicianId: 'physicianId',
        pathlabId: 'pathlabId',
        placedBy: 'placedBy',
        // receivedDate: 'receivedDate',
        orderDate: 'orderDate',
        reportDate: 'reportReadyDate',
        castleResult: 'castleResult',
        status: 'status',
      }
    } else {
      keys = {
        // patientName: 'patientName',
        patientFirstName: 'patientFirstName',
        patientLastName: 'patientLastName',
        patientDOB: 'patientDOB',
        orderType: 'orderType',
        testTypeCode: 'testTypeCode',
        castleId: 'castleId',
        specimenId: 'specimenId',
        organizationId: 'organizationId',
        physicianId: 'physicianId',
        // receivedDate: 'receivedDate',
        orderDate: 'orderDate',
        reportDate: 'reportReadyDate',
        castleResult: 'castleResult',
        status: 'status',
      }
    }

    if (screen === 'referral-orders') {
      apiFilters.onlyReferralOrders = true
    }

    if (screen === 'approvals') {
      delete keys.orderDate
      delete keys.reportDate
      delete keys.pathlabId
      delete keys.placedBy
    }

    Object.keys(filters).forEach((col: any) => {
      const input = filters[col]

      if (input) {
        let codes: any = {
          tc: '1',
          scc: '2',
          cm: '3',
          um: '4',
          ddm: '5',
          idg: '8',
          '1': '1',
          '2': '2',
          '3': '3',
          '4': '4',
          '5': '5',
          '8': '8',
          'tissue cypher': '1',
          'tissuecypher': '1',
          'decision dx-scc': '2',
          'decisiondx-scc': '2',
          'decision dx-melanoma': '3',
          'decisiondx-melanoma': '3',
          'decision dx-um': '4',
          'decisiondx-um': '4',
          'diffdx-melanoma': '5',
          'IDgenetix': '8'

        }

        if (Object.keys(keys)[Number(col)] === 'testTypeCode') {

          let foundKey = Object.keys(codes).find((key: any) => key.includes(input.toLowerCase()))
          apiFilters[Object.keys(keys)[Number(col)]] = codes[`${foundKey}`]
        } else if (((screen === 'unshipped-orders' && Number(col) === 6) || (isPathLab && Number(col) === 9) || Number(col) === 7 || Number(col) === 8) && typeof (input) === 'string') {
          apiFilters[Object.keys(keys)[Number(col)].replace('Id', 'Name')] = input
        } else {
          apiFilters[Object.keys(keys)[Number(col)]] = input
        }
      }

      if (apiFilters.organizationId === '') {
        delete apiFilters.organizationId
      }

      if (apiFilters.physicianId == '') {
        delete apiFilters.physicianId
      }

      if (apiFilters.pathlabId == '') {
        delete apiFilters.pathlabId
      }

      if (apiFilters.organizationName === '') {
        delete apiFilters.organizationName
      }

      if (apiFilters.physicianName === '') {
        delete apiFilters.physicianName
      }

      if (apiFilters.pathlabName === '') {
        delete apiFilters.pathlabName
      }

      debounceSearch(apiFilters)
    })



    let tableFilters: any = {}
    Object.keys(keys).forEach((key: string, index: number) => {
      tableFilters[index] = apiFilters[key]
    })
    setTableFilters(tableFilters)
    setFilters(apiFilters)
    setInfilter(true)
  }

  function handleFieldFilter(index: number) {

    setFields((prevState: any) => {
      let isSelected = !prevState[index].selected
      let fields = [...prevState, prevState[index].selected = isSelected]
      if (!isSelected) {
        if (groupFilterKey.current === fields[index].key) {
          groupFilterKey.current = ''
          setGroupedData([])
          setGroupByFilters((prevFilters: any) => prevFilters.filter((item: any) => item.key !== fields[index].key && item.label !== 'Ungroup'))
        } else {
          setGroupByFilters((prevFilters: any) => prevFilters.filter((item: any) => item.key !== fields[index].key))
        }
      } else {
        setGroupByFilters((prevFilters: any) => {
          if (prevFilters.some((item: any) => item.selected)) {
            return insert(prevFilters, index + 1, { ...fields[index], selected: false })
          }
          return insert(prevFilters, index, { ...fields[index], selected: false })
        })
      }

      const selectedFields = fields.filter((field) => field.selected).map((field) => field.key)
      let value = selectedFields.join(',').replace('pathabId', 'pathlabId')
      updateOrderTableSettings({
        value,
        // value: '',
        accessToken: authState?.accessToken?.accessToken ? authState.accessToken.accessToken : '',
        onError: (message: string) => toastMessages.error(message)
      })

      return fields.filter((field: any) => typeof (field) !== 'boolean')
    })
  }

  function handleGroupField(index: number) {
    let groupedData: any = []
    const currentGroupFilters = [...groupByFilters]

    if (currentGroupFilters[index].key == groupFilterKey.current) {
      groupFilterKey.current = (groupByFilters[index].key)
      groupFilterKey.current = ''
      groupedData = []
    } else {
      groupFilterKey.current = (currentGroupFilters[index].key)
      groupedData = groupBy(tableBody, (item: any) => item.data[currentGroupFilters[index].key])
    }
    let temp = [...currentGroupFilters]

    if (temp[index].label == 'Ungroup') {
      setGroupedData([])
      setGroupByFilters((prevState: any) => prevState.filter((item: any) => item.label !== 'Ungroup').map((item: any) => {
        return { ...item, selected: false }
      }))
      return
    }

    temp = temp.map((filter, itemIndex) => {
      if (index == itemIndex) {
        return { ...filter, selected: !filter.selected }
      }
      return { ...filter, selected: false }
    })

    const hasSelected = temp.some((filter: any) => filter.selected)

    if (hasSelected) {
      let foundUngroup = temp.find((item: any) => item.label === 'Ungroup')
      if (!!foundUngroup == false) {
        const array = [{ label: 'Ungroup', selected: false, key: 'Ungroup' }, ...temp]
        temp = array
      }
    } else {
      temp = temp.filter((filter: any) => filter.label !== 'Ungroup')
    }

    setGroupedData(groupedData)
    setGroupByFilters(temp)
  }

  function handleCheck(check: boolean, index: number): void {
    setTableBody((prevState: any) => {
      let temp = [...prevState]
      let newArray = temp.map((item: any, childIndex: number) => {
        if (childIndex === index) {
          let checkValue = !item.check
          return {
            ...item,
            check: checkValue,
            data: {
              ...item.data
            }
          }
        }
        return { ...item }
      })
      if (setAllPdfIds)
        setAllPdfIds(newArray.map((item: any) => `${item?.id}_${item?.data?.patientName ?? 'Patient'}_${item.data.testTypeCode}`))
      if (setButtonsDisabled)
        setButtonsDisabled(!newArray.some((item: any) => item.check === true))
      let checkedIds = newArray.filter((record: any) => record.check === true)
      let physicianIds = newArray.filter((record: any) => record.check).map((record: any) => record?.formattedData?.clinician_id)
      let checkedIdsTests = newArray.filter((record: any) => record.check).map((record: any) => record?.formattedData?.selectedTest?.id)
      // let checkedPhysicianIds = newArray.filter((item: any, childIndex: number) => index === childIndex).map((record: any) => record?.formattedData?.clinician_id)
      setCheckedIds(checkedIds.map((item: any) => item?.id))
      setCheckedPhysicianIds(physicianIds)
      setCheckedIdTests?.(checkedIdsTests)
      setPdfIds(checkedIds.map((item: any) => `${item?.id}_${item?.data?.patientName ?? 'Patient'}_${item?.data?.testTypeCode}`))
      return newArray
    })

  }
  function handlePrint(id: string) {
    setPrintIds([id])
  }
  async function disassociateLabel(values: any, onSuccess: Function, onError: Function) {
    try {
      const orderId = values.orderId
      const fedexLabelId = values.fedexLabelId

      const response = await http.disassociateLabel(
        "/v1/orders/disassociate-label",
        {
          orderId,
          fedexLabelId
        },
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { statusCode, message } = response;
      if (statusCode === 201) {
        getTableData({ ...filters, page: 1, size, fedexLabelId, organizationId: selectedOrganization?.id })

        toastMessages.success("Order removed!");
        onSuccess?.()
      }
    } catch (error: any) {
      toastMessages.error(error?.message)
      onError?.()
    }
  }

  function handleActionClick(item: any, buttonRefs: any) {
    const { item: data, type: action, index } = item;

    const token = authState?.accessToken?.accessToken ?? ''
    function handleDownloadOrder(): void {

      const onError = (error: any) => {
        toastMessages.error(error.message)
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (item?.id == data.id) {
            return { ...item, downloading: false }
          }
          return { ...item }
        }))
      }
      const onSuccess = () => {
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (item?.id == data.id) {
            return { ...item, downloading: false }
          }
          return { ...item }
        }))
      }
      setTableBody((prevState: any) => prevState.map((item: any) => {
        if (item?.id == data.id) {
          return { ...item, downloading: true }
        }
        return { ...item }
      }))
      let fileName = `${data?.id}_${!!data?.data.patientName ? data?.data?.patientName == 'N/A' ? 'Patient' : data?.data?.patientName : 'Patient'}_${data?.data?.testTypeCode ?? ''}`
      DownloadHelper.downloadMultiplePDF([{ id: data.id, fileName }], token, screen === 'approvals' || false, onError, onSuccess)
    }

    switch (action) {
      case 'authorize':
        history.push(`approvals/review?ids=${data.id}`)
        break;
      case 'print':
        handlePrint(data.id)
        break;
      case 'edit':
        if (data?.data?.orderStatus !== 'submitted') {
          history.push(`/test-orders/online-order/${data.id}`)
        }
        break;
      case 'upload':
        history.push(`test-orders/summary/${data.id}?section=document-management`)
        break;
      case 'download report':
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (item?.id == data.id) {
            return { ...item, downloading: true }
          }
          return { ...item }
        }))
        DownloadHelper.downloadReport(data.id, `${data?.data.patientName} ${data?.data?.testTypeCode ?? ''}`, token, (error: any) => {
          toastMessages.error(error.message)
          setTableBody((prevState: any) => prevState.map((item: any) => {
            if (item?.id == data.id) {
              return { ...item, downloading: false }
            }
            return { ...item }
          }))
        }, () => {
          setTableBody((prevState: any) => prevState.map((item: any) => {
            if (item?.id == data.id) {
              return { ...item, downloading: false }
            }
            return { ...item }
          }))
        })
        break;

      case 'remove':
        const setRemove = (loading: boolean) => setTableBody((prevState: any) => prevState.map((item: any) => {
          if (item?.id == data.id) {
            return { ...item, downloading: loading }
          }
          return { ...item }
        }))
        setRemove(true)
        disassociateLabel(data?.formattedData, () => setRemove(false), () => setRemove(false))
        break;
      default:
        handleDownloadOrder()
        break;
    }
  }

  function itemActions(record: any) {
    let actions: any[] = []

    if (record.status < 40) {
      screen !== 'referral-orders' ? actions.push('edit') : null
      if (!!record?.eRequestId)
        actions.push('download order')

    } else if (record?.status !== 100) {
      if (record?.status === 90) {
        if (!!record?.eRequestId)
          actions.push('download receipt')
      } else {
        if (!!record?.eRequestId)
          actions.push('download receipt')
      }
    }
    if (record?.status === 100 && !!record?.orderReport !== false && (Array.isArray(record?.orderReport) && record?.orderReport?.length > 0)) {
      actions.push('download report')
    }

    if (record?.status === 90) {
      if (!!record?.eRequestId)
        actions = ['download receipt']
    }

    return actions
  }
  const buildParams = (filters: any) => {
    let isPLU = profile?.roles?.includes('PathLab User')
    let removePhysicianInDocuments = screen === 'documents' && (profile?.roles?.length === 1 && profile?.roles?.includes('Physician'))

    if (!!filters?.status == false && screen !== 'unshipped-orders') {
      delete filters.status
    }

    if (screen === 'unshipped-orders' && !!filters.status == false) {
      filters.status = [110]
    }

    if (isPLU) {
      delete filters.organizationId
    }

    if (removePhysicianInDocuments) {
      delete filters?.physicianId
      delete filters?.organizationId
    }

    const params = new URLSearchParams()
    Object.keys(filters).forEach((key: string) => {
      if (Array.isArray(filters[key])) {
        filters[key].map((item: any) => params.append(`${key}[]`, item))
      } else
        if (filters[key] || filters[key] === 0)
          params.append(key, filters[key])
    })
    return params.toString()
  }
  async function getTableData(filters: any) {
    let physicians: any = []
    let tableData: any = []
    let updatedColumns: any = []
    let singlePrintRefs: any = []
    let isPLU = profile?.roles?.includes('PathLab User')
    setSearching(true)

    function updateTableHeader() {
      let pathLabColumns = ['PathLab', 'Placed By', 'Label']
      let shipmentsNotRequiredColumns = ['Castle Result', 'Report Date', 'Castle ID', 'Order Type', 'Actions', 'PathLab', 'Placed By']

      updatedColumns = initialColumns
        .filter((column: any) => {
          if (screen === 'unshipped-orders') {
            return shipmentsNotRequiredColumns.includes(column.name) === false
          }
          if (screen === 'approvals') {
            if (column.name !== 'Ordered Date' && column.name !== 'Report Date' && column.name !== 'PathLab' && column?.name !== 'Placed By' && column.name !== 'Label')
              return true
            return false
          }

          if (isPathLab) {
            if (!!fedexLabelId || fedexLabelId === 0) {
              return column.name !== 'Label'
            }
            if (screen !== 'unshipped-orders')
              return column.name !== 'Label'
            return true
          }

          return pathLabColumns.includes(column.name) === false
        }).map((column: header) => {
          if (column?.name === 'Org') {
            return {
              ...column,
              filter: selectedOrganization && screen !== 'referral-orders' ? false : !isPLU ? true : false,
              suggestions: organizations?.map((org: any) => ({ label: org?.name, value: org?.id })),
              action: {
                ...column.action,
                type: 'sort',
                onClick(field: string, filter: string) {
                  onSort(field, filter, tableData, filters)
                },
                dropdownList: [
                  { label: 'Ascending', key: 'ascending' },
                  { label: 'Descending', key: 'descending' }
                ],
                currentSelection: { label: '', key: '' }
              },
            }
          }
          if (column?.name === 'PathLab') {
            return {
              ...column,
              filter: selectedPathLab ? false : screen !== 'dashboard' ? true : false,
              suggestions: pathLabs?.map((org: any) => ({ label: org?.name, value: org?.id })),
              action: {
                ...column.action,
                type: 'sort',
                onClick(field: string, filter: string) {
                  onSort(field, filter, tableData, filters)
                },
                dropdownList: [
                  { label: 'Ascending', key: 'ascending' },
                  { label: 'Descending', key: 'descending' }
                ],
                currentSelection: { label: '', key: '' }
              },
            }
          }
          if (column?.name === 'Clinician') {
            let physiciansList: suggestion[] = !!selectedOrganization ? selectedOrganization?.users?.filter((user: any) => user?.roles?.includes('Physician')).map((physician: any) => ({ label: `${physician?.firstName ?? 'Pending Entry'} ${physician?.lastName ?? 'Pending Entry'}`, value: physician?.id })) : physicians.map((physician: any) => ({ label: `${physician?.firstName ?? 'Pending Entry'} ${physician?.lastName ?? 'Pending Entry'}`, value: physician?.id }))

            return {
              ...column,
              name: 'Clinician',
              filter: selectedPhysician && screen !== 'referral-orders' ? false : !isPLU ? true : false,
              suggestions: physiciansList,
              action: {
                ...column.action,
                type: 'sort',
                onClick(field: string, filter: string): any {
                  onSort(field, filter, tableData, filters)
                },
                dropdownList: [
                  { label: 'Ascending', key: 'ascending' },
                  { label: 'Descending', key: 'descending' }
                ],
                currentSelection: { label: '', key: '' }
              },
            }
          }
          if (column?.name === 'Status') {

            let updatedSuggestions: any = column.suggestions
            let showStatusFilter = (screen === 'test-orders' || screen === 'referral-orders' || screen === 'documents' || screen === 'unshipped-orders') ? true : false


            if (!!filters.status) {
              if (filters.status.includes(105)) {
                updatedSuggestions = updatedSuggestions.map((suggestion: any) => {
                  if (suggestion.value == 100) {
                    return {
                      ...suggestion,
                      selected: true
                    }
                  }
                  return {
                    ...suggestion
                  }
                })
              } else {
                updatedSuggestions = updatedSuggestions.map((suggestion: any) => {
                  if (filters.status.includes(suggestion.value)) {
                    return {
                      ...suggestion,
                      selected: true
                    }
                  }
                  return {
                    ...suggestion
                  }
                })
              }
            }
            return {
              ...column,
              suggestions: updatedSuggestions.filter((item: any) => {
                let validValues = [40, 60, 65, 80, 90, 100]
                let unshippedOrderStatuses = [40, 60, 70, 80];
                if (isPLU) {
                  return validValues.includes(item.value)
                }
                if (screen === 'unshipped-orders' || !!fedexLabelId) {
                  return unshippedOrderStatuses.includes(item.value)
                }
                return true
              }),
              // filter: isPLU ? false : true,
              filter: showStatusFilter,
              action: {
                ...column.action,
                type: 'sort',
                onClick(field: string, filter: string) {
                  onSort(field, filter, tableData, filters)
                },
                dropdownList: [
                  { label: 'Ascending', key: 'ascending' },
                  { label: 'Descending', key: 'descending' }
                ],
                currentSelection: { label: '', key: '' }
              },
              handleMultiSelect(index: number) {
                if (column.suggestions && column?.suggestions?.length > 0) {
                  let suggestions = updatedSuggestions
                  if (index !== -1) {
                    suggestions = suggestions.map((item: any, itemIndex: number) => {
                      if (itemIndex == index) {
                        return {
                          ...item,
                          selected: !item.selected
                        }
                      }
                      return {
                        ...item,
                        selected: item.selected
                      }
                    })

                    setHeader((prevState: any) => ({
                      ...prevState,
                      data: [...updatedColumns.map((item: any) => {
                        if (item.name === 'Status') {
                          return {
                            ...item,
                            suggestions
                          }
                        } else
                          return { ...item }
                      })]
                    }))
                  } else {
                    setHeader((prevState: any) => ({
                      ...prevState,
                      data: [...updatedColumns.map((item: any) => {
                        if (item.name === 'Status') {
                          return {
                            ...item,
                            suggestions: item.suggestions.map((item: any) => ({ ...item, selected: false }))
                          }
                        } else
                          return { ...item }
                      })]
                    }))
                  }
                }
              },
            }
          }
          if (column?.name !== 'Actions')
            return {
              ...column,
              action: {
                ...column.action,
                type: 'sort',
                onClick(field: string, filter: string) {
                  onSort(field, filter, tableData, filters)
                },
                dropdownList: [
                  { label: 'Ascending', key: 'ascending' },
                  { label: 'Descending', key: 'descending' }
                ],
                currentSelection: { label: '', key: '' }
              },
            }

          return {
            ...column
          }
        })

      setHeader({
        hasVerticalMenu: false,
        onFilter(filters: any) {
          handleFilters(filters)
        },
        onSelectAll: () => {
          let checkValue = false
          setHeader((prevState: any) => {
            checkValue = !prevState.check
            return {
              ...prevState, check: checkValue
            }
          })
          setTableBody((prevState: any) => {
            let temp = [...prevState]
            let newArray = temp.map((item: any, childIndex: number) => {
              return {
                ...item,
                check: checkValue,
                data: {
                  ...item.data
                }
              }
            })

            setButtonsDisabled(!checkValue)
            let checkedIds = newArray.filter((record: any) => record.check === true)
            let physicianIds = newArray.filter((record: any) => record.check).map((record: any) => record?.formattedData?.clinician_id)
            let checkedIdsTests = newArray.filter((record: any) => record.check).map((record: any) => record?.formattedData?.selectedTest?.id)
            // let checkedPhysicianIds = newArray.map((record: any) => record?.formattedData?.clinician_id)
            setCheckedIds(checkedIds.map((item: any) => item?.id))
            setCheckedPhysicianIds(physicianIds)
            setCheckedIdTests?.(checkedIdsTests)
            setPdfIds(checkedIds.map((item: any) => `${item?.id}_${item?.data?.patientName ?? 'Patient'}_${item?.data?.testTypeCode}`))
            return newArray
          })

        },
        NavLink: '',
        nnProps: '',
        check: false,
        hideCheckBox: screen === 'approvals' || screen === 'unshipped-orders' ? false : true,
        data: updatedColumns,
        list: [{ name: 'name' },
        { email: 'email' }]
      })
    }

    try {
      const response = await http.getTableData(`/v1/orders/new-flow?${buildParams(filters)}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })

      const { data, message, statusCode } = response
      if (data?.orders?.length > 0) {
        singlePrintRefs = Array(data?.orders?.length)
          .fill(0)
          .map((_, i) => createRef())

        organizations?.map((org: any) => {
          let currentOrgPhysicians = org?.users?.filter((user: any) => user?.roles?.includes('Physician') ?? false
          )
          if (currentOrgPhysicians)
            physicians = [...physicians, ...currentOrgPhysicians]
          return physicians
        })
        tableData = data?.orders?.map((record: any) => {
          let formattedData = orderResponseFormatter(record)
          let physicianName = record?.physician ? record?.physician?.firstName + ' ' + record?.physician?.lastName : 'Pending Entry'
          let bodyData: any = {
            patientFirstName: record?.patientFirstName ? record?.patientFirstName : '[Pending Entry]',
            patientLastName: record?.patientLastName ? record?.patientLastName : '[Pending Entry]',
            patientDOB: record?.patientDOB ? record.patientDOB?.replaceAll?.('-', '/') : '[Pending Entry]',
            orderType: record?.orderType ?? 'N/A',
            testTypeCode: record?.testTypes?.name?.split?.(':')?.length > 1 ? record?.testTypes?.name?.split(':')?.[0] : record?.testTypes?.name ?? 'N/A',
            castle_id: !!record?.castleId ? record?.castleId : 'N/A',
            specimen_id: !!record.specimenId ? record.specimenId : 'N/A',
            organizationCode: record?.organization?.name ?? 'N/A',
            physicianCode: physicianName,
            pathlabId: !!record?.labortaryInfo?.labortaryName ? record?.labortaryInfo?.labortaryName : 'N/A',
            placedBy: physicianToClinician(record?.placedBy),
            // receivedDate: record.createdAt ? record.createdAt?.replaceAll('-','/') : 'N/A',
            orderDate: record.orderDate ? record.orderDate?.replaceAll('-', '/') : 'Pending Entry',
            reportDate: record?.reportReadyDate && record?.reportReadyDate !== null ? record.reportReadyDate?.replaceAll('-', '/') : 'N/A',
            castleResult: !!record?.castleResult ? record.castleResult : 'N/A',
            status: record?.orderStatus ?? 'Pending Approval',
            // actions: itemActions(record)
            actions: !!fedexLabelId || fedexLabelId === 0 ? ['remove'] : itemActions(record),

          }

          if (screen === 'unshipped-orders') {
            delete bodyData.actions
            delete bodyData.pathlabId
            delete bodyData.placedBy
            delete bodyData.castle_id
            delete bodyData.orderType
            delete bodyData.castleResult
            delete bodyData.pathlabId
            delete bodyData.placedBy
            delete bodyData.reportDate

            bodyData.label = record?.fedexLabel?.viewableUrl ? record.fedexLabel : 'N/A'

          } else if (!isPathLab) {
            delete bodyData.pathlabId
            delete bodyData.placedBy
          }

          let obj = {
            id: record.id,
            onRowClick: (id: number) => {
              if (screen === 'referral-orders') {
                history.push(`/test-orders/summary/${record?.id}?isReferralOrder=true`)
                return
              }

              if (screen !== 'approvals' && screen !== 'unshipped-orders') {
                history.push(`/test-orders/summary/${record?.id}`)
              }
            },
            actionClick: (item: any) => handleActionClick(item, singlePrintRefs),
            selectOnIndex: (check: boolean, index: number, data: any) => {
              handleCheck(check, index)
            },

            data: screen === 'approvals' ? {
              patientFirstName: record?.patientFirstName ? record?.patientFirstName : '[Pending Entry]',
              patientLastName: record?.patientLastName ? record?.patientLastName : '[Pending Entry]',
              patientDOB: record?.patientDOB ? record.patientDOB?.replaceAll('-', '/') : '[Pending Entry]',
              orderType: record?.orderType ?? 'N/A',
              testTypeCode: record?.testTypes?.name?.split?.(':')?.length > 1 ? record?.testTypes?.name?.split?.(':')?.[0] : record?.testTypes?.name ?? 'N/A',
              castle_id: !!record?.castleId ? record?.castleId : 'N/A',
              specimen_id: !!record.specimenId ? record.specimenId : 'N/A',
              organizationCode: record?.organization?.name ?? 'N/A',
              physicianCode: physicianName,
              castleResult: record?.castleResult ? record.castleResult : 'N/A',
              status: record?.orderStatus ?? 'Pending Approval',
              actions: !!fedexLabelId || fedexLabelId === 0 ? ['remove'] : ['print', 'downloadReport', 'authorize']
            } : bodyData,
            tooltips: [{
              key: 'organizationCode',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            },
            {
              key: 'pathlabId',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            }],
            stickyClass: (isPathLab || screen === 'unshipped-orders') && screen !== 'approvals' ? [
              { key: 'status', value: `sticky right-0 bg-white ${screen === 'unshipped-orders' ? 'right-[78px]' : 'right-[92px]'} shadow-leftShadow min-w-[10rem]` },
              { key: 'actions', value: 'sticky right-0 bg-white' },
              { key: 'label', value: 'sticky right-0 bg-white' }
            ] : [],
            formattedData,
            showCheckbox: screen === 'approvals' || screen === 'unshipped-orders' ? true : false,
            check: false,
            sensitiveFields: ['patientFirstName', 'patientLastName', 'patientDOB', 'specimen_id']
          }

          return obj
        })

        setTableBody(tableData)
        if (!!groupFilterKey.current && groupFilterKey.current !== 'Ungroup') {
          setGroupedData(groupBy(tableData, (item: any) => item.data[groupFilterKey.current]))
        } else {
          setGroupedData([])
        }
        setElRefs((elRefs: any) =>
          Array(data?.orders?.length)
            .fill(0)
            .map((_, i) => elRefs[i] || createRef()),
        );
        if (setButtonRefs) {
          setButtonRefs(singlePrintRefs)
        }
      } else {
        setTableBody([])
        setGroupedData([])
      }
      apiCalled.current = true;
      updateTableHeader()
      setFilters(filters)
      setPaginationData({
        totalItems: data.totalItems,
        totalPages: data.totalPages,
        currentPage: data.currentPage
      })
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)
    } catch (error: any) {
      organizations?.map((org: any) => {
        let currentOrgPhysicians = org?.users?.filter((user: any) => user?.roles?.includes('Physician') ?? false
        )
        if (currentOrgPhysicians)
          physicians = [...physicians, ...currentOrgPhysicians]
        return physicians
      })
      updateTableHeader()
      toastMessages.error(error?.message ? error.message : error.error ? error.error : 'Something went wrong')
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)
    }
  }

  function handleBack() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage - 1, size })
  }

  function handleItemsPerPage(size: number) {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    setSize(size)
    getTableData({ ...filters, page: 1, size })
  }

  function handleNext() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage + 1, size })
  }

  function onSort(sortField: string, sortDirection: string, data: any, filters: any) {
    let fields: any = {
      // 'Patient': 'patientName',
      'Patient First Name': 'patientFirstName',
      'Patient Last Name': 'patientLastName',
      'Patient DOB': 'patientDOB',
      'Order Type': 'orderType',
      'Test Type': 'testTypeId',
      'Castle ID': 'castleId',
      'Specimen ID': 'specimenId',
      'Org': 'organizationId',
      'Clinician': 'physicianId',
      'PathLab': 'labortaryId',
      'Placed By': 'placedBy',
      'Castle Result': 'castleResult',
      // 'Ordered Date': 'createdAt',
      'Ordered Date': 'orderDate',
      'Report Date': 'reportReadyDate',
      'Status': 'status'
    }

    getTableData({
      ...filters,
      sortBy: fields[sortField],
      sortDirection: sortDirection === 'ascending' ? 'ASC' : 'DESC'
    })
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    let currentSequence: any = null
    let newSequence = []
    let oldIndex: number
    let newIndex: number
    let newKeys: any = {}

    if (active?.id !== over?.id) {
      setTableBody((items) => {
        currentSequence = Object.keys(items[0].data)
        newSequence = []

        oldIndex = currentSequence.indexOf(active?.id);
        newIndex = currentSequence.indexOf(over?.id);

        newSequence = arrayMove(currentSequence, oldIndex, newIndex)
        newSequence.map((key: any) => { newKeys[key] = '' })
        let array = items.map((item: any) => {
          let data = { ...newKeys, ...item.data }
          return { ...item, data }
        })
        if (!!groupFilterKey.current && groupFilterKey.current !== 'Ungroup') {
          const groupedData = groupBy(array, (item: any) => item.data[groupFilterKey.current])
          setGroupedData(groupedData)
        } else {
          setGroupedData([])
        }
        return array
        // return arrayMove(items, oldIndex, newIndex);
      });
      setHeader((prevState: any) => {
        return {
          ...prevState,
          data: arrayMove(prevState.data, oldIndex, newIndex)
        }
      })
    }
  }

  function getInitialData(filters: any) {
    let currentFilters = { ...filters }
    const props: any = { selectedOrganization, selectedPhysician, selectedPathLab }
    if (screen !== 'referral-orders') {
      const filterValues: any = {
        selectedOrganization: 'organizationId',
        selectedPathLab: 'pathlabId',
        selectedPhysician: 'physicianId'
      }

      Object.keys(props).forEach((key: string) => {
        if (props[key]?.id) {
          currentFilters = { ...currentFilters, [filterValues[key]]: props[key]?.id }
        }
      })
    }
    setFetching(true)
    getTableData(currentFilters)
    setFilters(currentFilters)
  }

  const handleSettingsFields = (orderTableSettings: SettingsItem | undefined) => {
    if (orderTableSettings && screen === "test-orders") {
      setFields(dataConstants.testOrdersGroupColumnsList.map((filter) => ({ ...filter, selected: orderTableSettings?.value?.includes(filter.key) })))
    } else {
      setFields(dataConstants.testOrdersGroupColumnsList.map((filter) => ({ ...filter, selected: true })))
    }

  }

  useEffect(() => {
    if (screen === 'referral-orders' && apiCalled.current) return
    let isPathLab = profile?.roles?.includes('Collaborative Order Agreement') || profile?.roles?.includes('PathLab User')
    let isPLU = profile?.roles?.includes('PathLab User')
    if (!isPathLab) {
      let filteredFields = dataConstants.testOrdersGroupColumnsList.filter((filter: any) => {
        let pluFields = ['Placed By', 'PathLab']
        return pluFields.includes(filter.label) === false
      })
      setGroupByFilters(filteredFields)
      setFields(filteredFields.map((filter: any) => ({ ...filter, selected: true })))
    }

    if (loading)
      return

    let filters: any = {
      size,
      page: 1,
    }

    filters.fedexLabelId = fedexLabelId


    if (screen === 'approvals' && (profile?.roles?.includes('Physician') || profile?.roles?.includes('Office Manager'))) {
      filters.status = [35]
    } else if (screen === 'approvals') {
      filters.status = [30]
    } else if (screen === 'unshipped-orders') {
      filters.status = [110]
    } else if (screen === 'dashboard') {
      if (isPLU) {
        filters.status = [75]
      } else {
        filters.status = [85]
      }
    } else if (screen === 'documents') {
      filters.status = [95]

    } else if (screen === 'referral-orders') {
      filters.onlyReferralOrders = true
    } else if (!!query.get('status')) {

      let statuses: any = {
        'In Complete': [25],
        'pending approval': [30],
        'coa order attestation': [55],
        'pending results': [70],
        'submitted': [40],
        'completed': [105],
      }

      let statusesText: any = {
        'In Complete': 'In Complete',
        'pending approval': 'Pending Approval',
        'coa order attestation': 'COA Order Attestation',
        'pending results': 'Pending Results',
        'submitted': 'Submitted',
        'completed': 'Completed',
      }

      let foundQueryStatusValue = Object.keys(statuses).find((key: string) => query?.get('status')?.includes(key))

      filters.status = statuses[foundQueryStatusValue ? foundQueryStatusValue : query.get('status')]

      setTableFilters((prevState: any) => ({ ...prevState, 10: statusesText[query.get('status')] }))
    }

    const props: any = { selectedOrganization, selectedPhysician, selectedPathLab }
    if (isPLU) {
      delete props.selectedOrganization
    }

    if (screen !== 'referral-orders') {
      const filterValues: any = {
        selectedOrganization: 'organizationId',
        // selectedPathLab: 'labortaryId',
        selectedPathLab: 'pathlabId',
        selectedPhysician: 'physicianId'
      }

      Object.keys(props).forEach((key: string) => {
        if (props[key]?.id) {
          filters = { ...filters, [filterValues[key]]: props[key]?.id }
        }
      })
    }

    setTableFilters(filters)
    getInitialData(filters)

    if (Object.keys(filters?.length === 0)) {
      setTableFilters({})
    }

    if (!!orderTableSettings?.value) {
      handleSettingsFields(orderTableSettings)
    } else
      fetchSettings({
        settingsType: 'ORDER_TABLE_FIELDS',
        onError: () => { },
        onSuccess: (data: SettingsItem[]) => {
          let orderTableSettings = data.find((setting) => setting.type === 'ORDER_TABLE_FIELDS')
          handleSettingsFields(orderTableSettings)
        },
        accessToken: authState?.accessToken?.accessToken ? authState.accessToken.accessToken : ''
      })

  }, [loading, selectedOrganization?.id, selectedPhysician?.id, selectedPathLab?.id, fetchingOrgUsers, profile, refetch])

  if (fetching)
    return <div className=''>
      {screen === 'documents' && <div className='flex justify-between items-center pb-[84px] -mb-12'>
        <p className='font-semibold text-royalBlue'>Attach documents to existing orders</p>
        <Button btnType='secondary' title={refreshing ? 'refreshing' : "refresh"} submitting={refreshing} disabled onClick={() => { }} />
      </div>}
      <TableLoadingIndicator />
    </div>

  return <Fragment>
    {screen === 'documents' && <div className='flex justify-between items-center'>
      <p className='font-semibold text-royalBlue'>Attach documents to existing orders</p>
      <Button btnType='secondary' title='refresh' onClick={() => {
        setRefreshing(true)
        getInitialData({
          size,
          page: 1,
          status: filters.status
        })
      }} />
    </div>
    }
    {multiPrinting ? null : <PDFPrint
      onError={(message: string) => {
        toastMessages.error(message)
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (printIds.includes(item?.id)) {
            return { ...item, printing: false }
          }
          return { ...item }
        }))
        setPrintIds([])
      }}
      onStart={() => {
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (printIds.includes(item?.id)) {
            return { ...item, printing: true }
          }
          return { ...item }
        }))
        setPrintIds([])
      }}
      onSuccess={() => {
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (printIds.includes(item?.id)) {
            return { ...item, printing: false }
          }
          return { ...item }
        }))
        setPrintIds([])
      }}
      ids={printIds}
    />}

    <div className="py-5 overflow-visible">

      {hideButtons
        ? null
        : <div className="flex items-center justify-between">
          <div className='flex items-center'>
            <TableFilterDropdown
              title={'Hide/Unhide Columns'}
              Icon={FilterIcon}
              onItemClick={handleFieldFilter}
              containerStyle="mr-6"
              list={fields}
            />
            <TableFilterDropdown
              title={groupByFilters.some((filter) => filter.selected == true) ? groupByFilters.find((filter) => filter.selected).label : 'Group By'}
              Icon={FilterIcon}
              onItemClick={handleGroupField}
              list={groupByFilters}
            />
          </div>

          {/* {Object.keys(filters).includes('sortBy') && <Button
            title={'Clear Filters'}
            btnType='transparent'
            className='mx-2 !text-royalBlue font-normal'
            onClick={() => {
              setTableFilters({})
              getInitialData({ size: 15, page: 1 })
            }} />} */}
          {/* <Button
            title={arrangeColumns ? 'Disable arrange columns' : 'Arrange Columns'}
            btnType='transparent'
            className='mx-2 !text-royalBlue font-normal'
            onClick={() => setArrangeColumns((prevState: boolean) => !prevState)} /> */}
        </div>}
      {
        fields.some((field) => field.selected == true)
          ? <div>
            <DndContext
              sensors={sensors}
              modifiers={[restrictToHorizontalAxis]}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                // disabled={!arrangeColumns}
                disabled
                items={tableBody?.length > 0 ? Object.keys(tableBody?.[0]?.data) : []}
                strategy={horizontalListSortingStrategy}
              >
                <DataTable
                  NavLink={''}
                  externalCheckbox={screen === 'approvals' || screen === 'unshipped-orders'}
                  onMouseEnter={() => setArrangeColumns(true)}
                  onMouseLeave={() => setArrangeColumns(false)}
                  initialFilters={tableFilters}
                  className={`${screen === 'approvals' ? '!max-h-[70vh]' : screen === 'dashboard' ? '!max-h-[45vh]' : '!max-h-[65vh]'} border-t ${tableBody?.length > 10 ? 'border-b' : ''} !min-h-[50vh] ${className}`}
                  customEmptyText={valueinFilter === false ? 'Currently, there are no test orders available.' : 'There are no test orders that match your search criteria.'}
                  visibleFields={fields}
                  body={tableBody}
                  groupedData={groupedData}
                  header={header}
                  showLoader={searching || updatingSettings}
                />
              </SortableContext>
            </DndContext>
            {paginationData && <Pagination
              paginationData={paginationData}
              submitting={fetchingPage}
              handleBack={handleBack}
              handleNext={handleNext}
              handleItemsPerPage={handleItemsPerPage}
              size={size} />}
          </div>
          : <p className="text-royalBlue py-3">Please select a column to view data</p>
      }
    </div>
  </Fragment >
}

const mapStateToProps = (state: any) => ({
  fetchingSettings: state.Settings.fetching,
  updatingSettings: state.Settings.updating,
  settings: state.Settings.data
})

const mapDispatchToProps = {
  fetchSettings: SettingsActions.fetchSettings,
  updateOrderTableSettings: SettingsActions.updateOrderTableSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(TestOrdersTable)
