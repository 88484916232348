import { useOktaAuth } from "@okta/okta-react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import WarningIcon from "../../../assets/svg/WarningIcon";
import Button from "../../../components/atomic/Button";
import Header from "../../../components/global/Header";
import TestOrdersTable from "../../../components/TestOrders/TestOrdersTable";
import { setButtonState } from "../../../Store/Actions/ButtonStateActions";
import DashboardActions from "../../../Store/Actions/DashboardActions";
import toastMessages from "../../../utils/helpers/toastMessage";
import DashboardCallModel from "../../../models/DashboardCallModel";

function StatusMessage({ onClick, isPhysicianOROfficeManager }: any) {
  const { data, fetching } = useSelector((state: any) => state.Dashboard);
  if (fetching)
    return (
      <div className="border border-dropDownBorder rounded p-3 bg-white flex justify-between items-center">
        <div className="flex items-center bg-dropDownBorder animate-pulse rounded-md">
          {/* <WarningIcon className="mr-2" /> */}
          {isPhysicianOROfficeManager ? (
            <p className="text-dropDownBorder">
              {" "}
              pending approvals & attestation orders
            </p>
          ) : (
            <p className="text-dropDownBorder"> pending approvals</p>
          )}
        </div>
        <Button
          title={"view orders"}
          btnType="transparent"
          onClick={onClick}
          className="!text-dropDownBorder bg-dropDownBorder animate-pulse !hover:text-dropDownBorder rounded-md"
        />
      </div>
    );
  if (data?.pendingApproval > 0)
    return (
      <div className="border border-requiredRed rounded p-3 bg-pink flex justify-between items-center">
        <div className="flex items-center">
          <WarningIcon className="mr-2" />
          {isPhysicianOROfficeManager ? (
            <p className="text-requiredRed">
              {data?.pendingApproval} pending approvals & attestation orders
            </p>
          ) : (
            <p className="text-requiredRed">
              {data?.pendingApproval} pending approvals
            </p>
          )}
        </div>
        <Button
          title={"view orders"}
          btnType="transparent"
          onClick={onClick}
          className="!text-requiredRed !hover:text-requiredRed"
        />
      </div>
    );
  return null;
}

function TestOrdersScreen({
  fetching,
  error,
  data,
  fetchDashboard,
  selectedPhysician,
  selectedOrganization,
  selectedPathLab,
  profile,
}: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState } = useOktaAuth();
  let isPhysicianOROfficeManager = profile?.profile?.roles?.includes("Physician") || profile?.profile?.roles?.includes("Office Manager");

  function openOrders() {
    history.push("/approvals", { replace: true });
  }

  useEffect(() => {
    let isPLU = profile?.profile?.roles?.includes("PathLab User");
    if (history.location.pathname.includes("test-orders")) {
      if (fetching === false || error == true) {
        const options: DashboardCallModel = {
          onError: (message: string) =>
            toastMessages.error(message ? message : "Something Went wrong"),
          config: {
            headers: {
              Authorization: `Bearer ${authState?.accessToken?.accessToken} `,
            },
          },
          pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
          organizationId: selectedOrganization?.id
            ? selectedOrganization.id
            : null,
          physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
          isPLU,
        };

        fetchDashboard(options);
      }
    }
    dispatch(setButtonState("create order"));
  }, [selectedOrganization?.id, selectedPhysician?.id, selectedPathLab?.id]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Header isInternal title={"Test orders"} />
        <div className="p-4 py-5">
          <StatusMessage onClick={openOrders} isPhysicianOROfficeManager={isPhysicianOROfficeManager} />
          <TestOrdersTable screen="test-orders" hideButtons={false} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
const mapStateToProps = (state: any) => ({
  fetching: state.Dashboard.fetching,
  error: state.Dashboard.error,
  data: state.Dashboard.data,
  selectedOrganization: state.OnlineOrderForm.selectedOrganization,
  selectedPhysician: state.OnlineOrderForm.selectedPhysician,
  selectedPathLab: state.OnlineOrderForm.selectedPathLab,
  profile: state.Profile,
});

const mapDispatchToProps = {
  fetchDashboard: DashboardActions.fetchDashboard,
};
export default connect(mapStateToProps, mapDispatchToProps)(TestOrdersScreen);
